import { BillingGraphProvider } from "../graphs/billing/BillingGraphContext";
import { FiltersProvider } from "../graphs/filters/FiltersContext";
import { SalesGraphProvider } from "../graphs/sales/SalesGraphContext";
import { TicketsGraphProvider } from "../graphs/tickets/TicketsGraphContext";
import { VisitsGraphProvider } from "../graphs/visits/VisitsGraphContext";
import ReportsPage from "../pages/ReportsPage";

export default function ReportRouterWrapper() {
  return (
    <FiltersProvider>
      <SalesGraphProvider>
        <TicketsGraphProvider>
          <VisitsGraphProvider>
            <BillingGraphProvider>
              <ReportsPage></ReportsPage>
            </BillingGraphProvider>
          </VisitsGraphProvider>
        </TicketsGraphProvider>
      </SalesGraphProvider>
    </FiltersProvider>
  );
}
