import { useContext, useEffect } from "react";
import {
  BillingGraphContext,
} from "./BillingGraphContext";
import useFilters from "@beeldit/core/helpers/useFilters";
import useBillingIndex from "./useBillingIndex";
import { FiltersContext } from "../filters/FiltersContext";
import Chart from "react-google-charts";

function useBillingGraph(user: any, filters: any = {}): any {
  /** Crud functions */
  const index = useBillingIndex();

  /** Filter loading */
  const { filtersLoaded } = useContext(FiltersContext);
  const { tableFilters } = useContext(FiltersContext);
  useFilters({ ...{}, ...filters }, FiltersContext);

  /** Index table funcion definition */
  const { graphData } = useContext(BillingGraphContext);

  useEffect(() => {
    if (filtersLoaded && tableFilters.start_date && tableFilters.end_date) {
      index();
    }
  }, [tableFilters, filtersLoaded]);

  /**<newActionsFunctions>**/

  let graphTemplate = filtersLoaded ? (
    <>
      {graphData ? (
        <>
          <Chart
            chartType="Bar"
            data={graphData}
            width="100%"
            height="400px"
            legendToggle
          />
        </>
      ) : (
        <div>No data to display</div>
      )}
    </>
  ) : (
    <>Loading ...</>
  );
  /** END EDIT TABLE ACTION */

  return {
    graphTemplate,
  };
}

export default useBillingGraph;
