import { Minus, Plus } from "@phosphor-icons/react";
import { ArrayFieldTemplateProps } from "@rjsf/utils";


function InvoiceFormArrayTemplate(props: ArrayFieldTemplateProps) {
  return (
    <div className="my-2">
      <div>
        {props.items.map((element, index) => {
          return (
            <>
              <div className="d-flex">
                <div className="flex-grow-1">{element.children}</div>
                <div className="delete-line d-flex flex-column align-items-start">
                  <button
                    className="btn btn-danger ms-4 ml-auto mt-auto"
                    onClick={element.onDropIndexClick(index)}
                  >
                    <Minus size={20} className="me-2 click" />
                    {/* <FontAwesomeIcon
                      icon={minus}
                      className="me-2 click"
                    /> */}
                  </button>
                </div>
              </div>
            </>
          );
        })}
      </div>
      <div className="mt-4 d-flex justify-content-end">
        <div className="add-line">
          {props.canAdd && (
            <button
              className="btn btn-primary ml-auto"
              onClick={props.onAddClick}
            >
              <Plus size={20} className="me-2 click" />
              {/* <FontAwesomeIcon
                icon={plus}
                className="me-2 click"
              /> */}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default InvoiceFormArrayTemplate;
