import React, { createContext, useState, useContext } from "react";
import { PaginationConfig } from "@beeldit/core/models/PaginationConfig.interface";
import { TableData } from "@beeldit/core/models/TableData.interface";
import { FinalTableConfig } from "@beeldit/core/models/FinalTableConfig.interface";

interface TenantAndCommerceContextType {
  tenantId: number | null;
  setTenantId: (tenantId: number | null) => void;
  commerceId: number | null;
  setCommerceId: (commerceId: number | null) => void;  
  commerce: any;
  setCommerce: (commerce: any) => void;
  tenant: any;
  setTenant: (tenant: any) => void;
}

export const TenantAndCommerceContext = createContext<TenantAndCommerceContextType>({
  tenantId: null,
  setTenantId: () => {},
  commerceId: null,
  setCommerceId: () => {},
  commerce: null,
  setCommerce: () => {},
  tenant: null,
  setTenant: () => {},
});

interface TenantAndCommerceProviderProps {
  children: React.ReactNode;
}

export const TenantAndCommerceProvider: React.FC<TenantAndCommerceProviderProps> = ({
  children,
}) => {
  const [tenantId, setTenantId] = useState<number | null>(null);
  const [commerceId, setCommerceId] = useState<number | null>(null);
  const [commerce, setCommerce] = useState<any>(null);
  const [tenant, setTenant] = useState<any>(null);

  const setInLocalstorage = (key: string, value: any) => {
    // Guardamos en localstorage con una fecha de expiración de 1 semana
    localStorage.setItem(
      key,
      JSON.stringify({
        value: {
          data: value,          
        },
        expiration: new Date().getTime() + 1000 * 60 * 60 * 24 * 7,
      })
    );
  };

  const setTenantInLocalstorage = (value: any) => {
    const localstorageKey = `tenant-${value.id}`;
    setTenant(value);
    setInLocalstorage(localstorageKey, value);
  };

  const value = {
    tenantId,
    setTenantId,
    commerceId,
    setCommerceId,
    commerce,
    setCommerce,
    tenant,
    setTenant: setTenantInLocalstorage,
  };
  return (
    <TenantAndCommerceContext.Provider value={value}>
      {children}
    </TenantAndCommerceContext.Provider>
  );
};
