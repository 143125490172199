import React, { useContext, useEffect, useState } from "react";

import { ListActionProvider } from "../actions/list/ListActionContext";
import { RemoveActionProvider } from "../actions/remove/RemoveActionContext";
import { CreateActionProvider } from "../actions/create/CreateActionContext";
import { EditActionProvider } from "../actions/edit/EditActionContext";
import NotificationsPage from "../pages/NotificationsPage";
import { TestEmailProvider } from "../actions/test-email/TestEmailContext";

export default function NotificationRouterWrapper(props: {
  config?: any;
  filters?: any;
  key?: string;
  formDefaultValues?: any;
  formFilterValues?: any;
}) {
  const { config, filters, key, formDefaultValues, formFilterValues } = props;

  return (
    <TestEmailProvider key={key}>
    <ListActionProvider>
      <RemoveActionProvider>
        <CreateActionProvider>
          <EditActionProvider>
            <NotificationsPage
              formDefaultValues={formDefaultValues}
              config={config}
              filters={filters}
              formFilterValues={formFilterValues}
            />
          </EditActionProvider>
        </CreateActionProvider>
      </RemoveActionProvider>
    </ListActionProvider>
    </TestEmailProvider>
  );
}
