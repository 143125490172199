import { useContext, useEffect, useRef, useState } from "react";
import { showSuccessMessage } from "@beeldit/core/helpers/messagesHelper";
import { FloppyDisk } from "@phosphor-icons/react/dist/ssr";
import useUpdateSection from "./useUpdateSection";
import { DetailsByPageableActionContext } from "../detailsByPageable/DetailsByPageableActionContext";
import { DetailsActionContext } from "../details/DetailsActionContext";

function useUpdateSectionAction(): any {
  const sectionRef = useRef<HTMLDivElement>(null);
  const [sectionId, setSectionId] = useState<string | null>("");

  const {
    element: page,
    setElement: setPage,
    selectedLang: selectedLang,
  } = useContext(DetailsByPageableActionContext);
  const {
    element: page2,
    setElement: setPage2,
    selectedLang: selectedLang2,
  } = useContext(DetailsActionContext);

  const finalPage = page || page2;
  const finalSelectedLang = selectedLang || selectedLang2;
  const finalSetPage = page ? setPage : setPage2;

  /**
   * Form store success function definition
   */
  const onSuccessUpdate = (section: any) => {
    showSuccessMessage("successfull_action");
    // Actualizamos la section de la página con el mismo id
    let sectionIndex = finalPage.sections.findIndex(
      (s: any) => s.id == section.id
    );
    finalPage.sections[sectionIndex] = section;
    finalSetPage({ ...finalPage });
  };

  const updateSection = useUpdateSection();
  const updateSectionFunction = () => {
    // Buscamos en finalPage la sección con el id sectionId y hacemos un setSectionId
    let section = finalPage.sections.find((s: any) => s.id == sectionId);
    section.lang = finalSelectedLang;
    updateSection(finalPage.id, sectionId, section, onSuccessUpdate);
  };
  useEffect(() => {
    if (sectionRef.current) {
      // Encontrar el ancestro más cercano con el atributo `data-section-id`
      const ancestor = sectionRef.current.closest("[data-section-id]");
      if (ancestor) {
        console.log("Ancestro encontrado:", ancestor);
        let id = ancestor.getAttribute("data-section-id");
        setSectionId(id);
      }
    }
  }, []);

  const actionTemplate = (
    <div ref={sectionRef} className="d-inline">
      <a
        href="javascript:;"
        onClick={() => {
          updateSectionFunction();
        }}
      >
        <FloppyDisk size={24} />
      </a>
    </div>
  );

  /** END EDIT FORM ACTION */

  return {
    actionTemplate,
  };
}

export default useUpdateSectionAction;
