function useProcessSchema() {
    
    function processSchema(schema: any, element: any) {
        let processedSchema = {...schema}; // Duplicamos el esquema para no modificar el original
        
        // Recorremos todas las propiedades del esquema
        Object.keys(processedSchema.properties).forEach((key) => {
            let property = processedSchema.properties[key];
            
            // Si la propiedad tiene la propiedad 'show'
            if (property.hasOwnProperty('show')) {
                // Si 'show' es falso, eliminamos la propiedad del esquema
                if (!property.show) {
                    element[key] = property.default;
                    delete processedSchema.properties[key];
                } 
                // Si 'show' es verdadero, eliminamos la propiedad 'show' del esquema
                else {
                    delete property.show;
                }
            }
            
            // Si la propiedad es un objeto, aplicamos la recursividad
            if (processedSchema.properties[key] && property.type === 'object' && property.properties) {
                processedSchema.properties[key] = processSchema(property, element[key] || {});
            }

            // Si la propiedad es un array de objetos, aplicamos la recursividad a cada elemento
            if (processedSchema.properties[key] && property.type === 'array' && property.items && property.items.type === 'object' && property.items.properties) {
                processedSchema.properties[key].items = processSchema(property.items, element[key] ? element[key][0] : {});
            }
        });
        
        return processedSchema;
    }
    
    return (schema: any, element: any) => {
        return processSchema(schema, element);
    }
}

export default useProcessSchema;
