import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/core";
import { useTranslation } from "react-i18next";
import { EventBlockingRuleContext } from "../contexts/EventBlockingRuleContext";
import { MouseEventHandler, useContext, useEffect, useState } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import EventSelector from "@main/events/components/EventSelector";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
    context: any;
    className?: string;
  };
}

interface Context {
  element: any;
  setElement: (element: any) => MouseEventHandler;
  backendFormErrors: any;
}

function EventBlockingRuleForm(props: Prop) {
  const { formRef, onSubmit, context, className } = props.formConfig;
  const { element, setElement, backendFormErrors } =
    useContext<Context>(context);

  const { t } = useTranslation();

  const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});

  const translations = {
    name: t("Name"),
    weekday: t("Weekday"),
    special_date: t("Special date"),
    time_start: t("Time Start"),
    time_end: t("Time end"),
    none: t("none"),
    sunday: t("sunday"),
    monday: t("monday"),
    tuesday: t("tuesday"),
    wednesday: t("wednesday"),
    thursday: t("thursday"),
    friday: t("friday"),
    saturday: t("saturday"),
  };

  let schema: RJSFSchema = {
    type: "object",
    // required: ['minutes', 'customer_id', 'task', 'date', 'report_id'],
    properties: {
      name: {
        type: "string",
        title: translations.name,
      },
      weekday: {
        type: ["integer", "null"],
        title: translations.weekday,
        default: null,
        oneOf: [
          {
            const: null,
            title: translations.none,
          },
          {
            const: 1,
            title: translations.monday,
          },
          {
            const: 2,
            title: translations.tuesday,
          },
          {
            const: 3,
            title: translations.wednesday,
          },
          {
            const: 4,
            title: translations.thursday,
          },
          {
            const: 5,
            title: translations.friday,
          },
          {
            const: 6,
            title: translations.saturday,
          },
          {
            const: 7,
            title: translations.sunday,
          },
        ],
      },
      special_date: {
        type: ["string", "null"],
        title: translations.special_date,
        format: "date",
        default: null,
      },
      time_start: {
        type: "string",
        title: translations.time_start,
        format: "time",
      },
      time_end: {
        type: "string",
        title: translations.time_end,
        format: "time",
      },
    },
  };
  const uiSchema: UiSchema = {
    "ui:classNames": className,
    "ui:submitButtonOptions": {
      props: {
        disabled: true,
        className: "btn btn-info",
      },
      norender: true,
    },
  };
  const customWidgets = {
    ...widgets,
    ...{ "event-selector": EventSelector },
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    if (type.formData.special_date === undefined) {
      type.formData.special_date = null;
    }
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  const processSchemaFunction = useProcessSchema();

  useEffect(() => {
    setFinalSchema(processSchemaFunction(schema, element));
  }, [element]);

  return (
    <JSONSchemaForm
      noHtml5Validate
      showErrorList={false}
      ref={formRef}
      schema={schema}
      uiSchema={uiSchema}
      widgets={customWidgets}
      formData={element}
      validator={validator}
      onChange={handleChange}
      onSubmit={onSubmit}
      onError={log("errors")}
      extraErrors={backendFormErrors}
    />
  );
}

export default EventBlockingRuleForm;
