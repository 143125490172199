import BeelditFileInput from "@beeldit/core/components/BeelditFileInput";
import BeelditTypeSelector from "@beeldit/core/components/BeelditTypeSelector";
import BeelditWYSIWYGInput from "@beeldit/core/components/BeelditWYSIWYGInput";
import BeelditSwitchInput from "@beeldit/core/components/BeelditSwitchInput";
import BeelditTypeMultiSelector from "../components/BeelditTypeMultiSelector";
import BeelditMultipleFileInput from "../components/BeelditMultipleFileInput";
import BeelditRadioInput from "../components/BeelditRadioInput";
import BeelditYesNoSelector from "../components/BeelditYesNoSelector";
import BeelditInput from "../components/BeelditInput";
import BeelditInputTextArea from "../components/BeelditInputTextArea";

const widgets: any = {
  "type-selector": BeelditTypeSelector,
  "type-multi-selector": BeelditTypeMultiSelector,
  "file-input": BeelditFileInput,
  "multiple-file-input": BeelditMultipleFileInput,
  "wysiwyg": BeelditWYSIWYGInput,
  "switch": BeelditSwitchInput,
  "radio-button": BeelditRadioInput,
  "yes-no-selector": BeelditYesNoSelector,
  TextWidget: BeelditInput,
  TextareaWidget: BeelditInputTextArea,
};

export default widgets;
