import { ObjectFieldTemplateProps } from "@rjsf/utils";
import useUpdateSectionAction from "../../actions/updateSection/useUpdateSectionAction";

function HouseFeaturesTemplate(props: ObjectFieldTemplateProps, func: any) {
  const { actionTemplate } = useUpdateSectionAction();
  return (
    <>
      <div className="cms-section">
        <div className="h3">{props.title} {actionTemplate}</div>
        <div className="section row">
          <div className="col-12">
            <div className="property-wrapper">
              {func("img", props)?.content}
            </div>
          </div>
          <div className="col-12">
            <div className="property-wrapper">
              {func("main_features", props)?.content}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HouseFeaturesTemplate;
