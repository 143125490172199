import { useContext } from "react";
import AddQueryParamsToURL from "@beeldit/core/helpers/AddQueryParamsToURL";
import useFilters from "@beeldit/core/helpers/useFilters";
import BeelditTableFilters from "@beeldit/core/components/BeelditTableFilters";
import { FiltersContext } from "./FiltersContext";
import getFilterUIConfig from "@main/reports/configs/filterUIConfig";
import getFiltersConfig from "@main/reports/configs/filterConfig";

function useFiltersAction(filters: any = {}): any {
  const addQueryParamsToURL: any = AddQueryParamsToURL();


  /** Filter loading */
  const { filtersLoaded, setFiltersLoaded } = useContext(FiltersContext);
  const { tableFilters, setTableFilters } = useContext(FiltersContext);  
  useFilters({ ...{}, ...filters }, FiltersContext);

  /** Table Filters configuration */
  const filterUIConfig = getFilterUIConfig();
  const filterConfig = {
    schema: getFiltersConfig(),
    uiSchema: filterUIConfig.uiScheme,
    widgets: filterUIConfig.widgets,
    element: tableFilters,
    onChange: (form: any) => {
      setTableFilters(form.formData);
      addQueryParamsToURL(form.formData);
    },
  };

  let actionTemplate =
    filtersLoaded && (
      <>
        <BeelditTableFilters formConfig={filterConfig} />        
      </>
    );
  /** END EDIT TABLE ACTION */

  return {
    actionTemplate,
  };
}

export default useFiltersAction;
