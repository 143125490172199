import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { RowAction } from "@beeldit/core/enums/RowAction.enum";
import { TableAction } from "@beeldit/core/enums/TableAction.enum";
import { FinalTableConfig } from "@beeldit/core/models/FinalTableConfig.interface";
import { Eye, Trash, Plus, ArrowsClockwise, Pencil, Gear } from "@phosphor-icons/react";

const tableConfig: FinalTableConfig = {
      index: null,
      actions: [
        {
          name: TableAction.CREATE, 
          label: 'create',
          showLabel: true,
          allowed: true,
          show: true,
          action: null,
          icon: <Plus size={20} />,
        },
      ],
      rowActions: [
        {
          name: RowAction.EDIT, 
          label: 'edit',
          icon: <Pencil size={20} />,
          allowed: true,
          show: true,
          action: (element: any) => {
            console.log('edit', element);
          }
        },
        {
          name: RowAction.DELETE, 
          label: 'delete',
          icon: <Trash size={20} />,
          allowed: true,
          show: true,
          action: (element: any) => { 
            console.log('delete', element);
          }              
        },
        {
          name: RowAction.DETAILS, 
          label: 'details',
          icon: <Eye size={20} />,
          allowed: true,
          show: true,
          action: (element: any) => { 
            console.log('details', element);
          }              
        },
        {
          name: 'generate-event-sessions', 
          label: 'generate-event-sessions',
          icon: <Gear size={20} />,
          allowed: true,
          show: (element: any) => {
            return !element.unique_date
          },
          action: (element: any) => { 
            console.log('generate-event-sessions', element);
          }              
        }
      ],
      rowConfiguration: {
        class: (element: any) => {
          return null;
        }
      },
      columns: [
        {
          name: 'id',
          label: '#',
          key: 'id',
          type: 'number',
          show: true,
          allowOrder: true,
        },
        {
          name: 'name',
          label: 'name',
          key: 'name',
          type: 'string',
          show: true,
          allowOrder: false,
        },
        {
          name: 'capacity',
          label: 'capacity',
          key: 'capacity',
          type: 'number',
          show: true,
          allowOrder: false,
        },
        {
          name: 'date_start',
          label: 'date_start',
          key: 'date_start',
          type: 'date',
          show: true,
          allowOrder: true,
        },
        {
          name: 'date_end',
          label: 'date_end',
          key: 'date_end',
          type: 'date',
          show: true,
          allowOrder: true,
        },
        {
          name:'event_type',
          label: 'event_type',
          key: 'event_type.name',
          type:'string',
          show: true,
          allowOrder: true,
        },
  
      ],
      pagination: {
        enabled: true,
        back: true
    }
}
const getConfig: any = (functions: any) => {
    const { 
      getFunction, 
      indexFunction, 
      initializeFunction, 
      prepareRemoveFunction, 
      detailsFunction,
      initializeGenerateEventSessionsFunction
    } = functions;
    tableConfig.actions[0].action = () => initializeFunction();
    tableConfig.index = () => indexFunction();
    tableConfig.rowActions[0].action = (id: any) => getFunction(id);
    tableConfig.rowActions[1].action = (id: any) => prepareRemoveFunction(id);
    tableConfig.rowActions[2].action = (id: any) => detailsFunction(id);
    tableConfig.rowActions[3].action = (id: any) => initializeGenerateEventSessionsFunction(id);
    return tableConfig;
}
export default getConfig;