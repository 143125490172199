import useErrorHandler from '@beeldit/core/errors/useErrorHandler';
import index from '@beeldit/core/services';
import { useContext } from 'react';
import { EventRulePriceContext } from '../contexts/EventRulePriceContext';

function useIndex() {    
    const {tableFilters, paginationConfig, orderBy, setTableData, setBackendFormErrors} = useContext(EventRulePriceContext);   
    const errorHandler = useErrorHandler();
    return () => {
      let filterPath = 'relations=event,eventPriceType';
      if (tableFilters) {
        for (const [key, value] of Object.entries(tableFilters)) {
          if(value) {
            filterPath += `&${key}=${value}`;
          }              
        }
      }
      if (paginationConfig) {
        for (const [key, value] of Object.entries(paginationConfig)) {
          if(value && key !== 'total_items') {
            filterPath += `&${key}=${value}`;
          }              
        }
      }
      if(orderBy) {
        filterPath += `&orderBy=${orderBy}`;
      }
      index('event_rule_prices', filterPath)
        .then((response: any) => {
          setTableData(response.data);
        })
        .catch((error: any) => {
          errorHandler(error, setBackendFormErrors)
        });
    }
  }
  
  export default useIndex;