import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import get from "@beeldit/core/services/get";

function useGetEventSessionPrices() {  
  const errorHandler = useErrorHandler();

  return (
    id: any,
    onSuccess: any = (data: any) => {
      return data;
    }
  ) => {
    get("event_sessions", id)
      .then((response: any) => {
        let data = response.data;
        onSuccess(response.data); 
      })
      .catch((error: any) => {
        errorHandler(error);
      });
  };
}

export default useGetEventSessionPrices;
