import { createRef, useContext } from "react";
import JSONSchemaForm from "@rjsf/core";
import CrudModuleFactoryModal from "@beeldit/core/components/CrudModuleFactoryModal";
import useGet from "./useGet";
import useUpdate from "./useUpdate";
import { EditAssignationActionContext } from "./EditAssignationActionContext";
import AssignmentForm from "@beeldit/resource-management/assignments/components/AssignmentForm";
import AssignationForm from "../../components/AssginationForm";

function useEditAssignationAction(index: any, assignmentId: number): any {
  const get = useGet();
  const getFunction = (id: number, assignationId: number) =>
    get(id, assignationId);

  /**
   * Form store success function definition
   */
  const onSuccessUpdate = () => {
    setShowModal(false);
    index(); // We pass the function to refresh the table on success
  };

  const update = useUpdate();
  const updateFunction = () => update(assignmentId, onSuccessUpdate);

  /** Creation and EditAssignationion modal function and state definition */
  const { showModal, setShowModal } = useContext(EditAssignationActionContext);

  /** Creation and EditAssignationion modal element definition */
  const { element } = useContext(EditAssignationActionContext);

  /**
   * Form Reference definition
   */
  const formRef = createRef<JSONSchemaForm>();

  /**
   * Creation and EditAssignationion modal configuration definition
   */
  const modalConfig = {
    title: "EditAssignation_assignment",
    context: EditAssignationActionContext,
    onSubmit: () => formRef.current?.formElement.current.requestSubmit(), //We pass the function for the submit of the modal
  };

  /**
   * Form configuration definition
   */
  const formConfig = {
    formRef: formRef, // We pass the ref to the form
    onSubmit: () => {
      updateFunction();
    },
    element: element, // We pass the element to the form in order to render it in the modal form
    context: EditAssignationActionContext,
  };

  const actionTemplate = (
    <CrudModuleFactoryModal modalConfig={modalConfig}>
      <AssignationForm formConfig={formConfig} />
    </CrudModuleFactoryModal>
  );

  /** END EditAssignation FORM ACTION */

  return {
    getFunction,
    actionTemplate,
  };
}

export default useEditAssignationAction;
