import React, { createContext, useState } from "react";

interface FiltersContextType {
  filtersLoaded: boolean;
  setFiltersLoaded: (filtersLoaded: boolean) => void;
  tableFilters: any;
  setTableFilters: (tableFilters: any) => void;  
}

export const FiltersContext = createContext<FiltersContextType>({
  filtersLoaded: false,
  setFiltersLoaded: () => {},
  tableFilters: {},
  setTableFilters: () => {},  
});

interface FiltersProviderProps {
  children: React.ReactNode;
}

export const FiltersProvider: React.FC<FiltersProviderProps> = ({
  children,
}) => {
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [tableFilters, setTableFilters] = useState({});
  
  const value = {
    filtersLoaded,
    setFiltersLoaded,
    tableFilters,
    setTableFilters,  
  };
  return (
    <FiltersContext.Provider value={value}>
      {children}
    </FiltersContext.Provider>
  );
};
