import React, { createContext, useState } from "react";

interface TicketsGraphContextType {
  graphData: any[];
  setGraphData: (graphData: any[]) => void;
}

export const TicketsGraphContext = createContext<TicketsGraphContextType>({
  graphData: [],
  setGraphData: () => {},
});

interface TicketsGraphProviderProps {
  children: React.ReactNode;
}

export const TicketsGraphProvider: React.FC<TicketsGraphProviderProps> = ({
  children,
}) => {
  const [graphData, setGraphData] = useState<any[]>();

  const value = {
    graphData,
    setGraphData,
  };
  return (
    <TicketsGraphContext.Provider value={value}>
      {children}
    </TicketsGraphContext.Provider>
  );
};
