import { createRef, useContext } from "react";
import JSONSchemaForm from "@rjsf/core";
import useGet from "./useGet";
import useUpdate from "./useUpdate";
import { useNavigate } from "react-router-dom";
import PageForm from "@bloonde/cms/cms-pages/components/PageForm";
import { showSuccessMessage } from "@beeldit/core/helpers/messagesHelper";
import { DetailsByPageableActionContext } from "./DetailsByPageableActionContext";

function useDetailsByPageableAction(pageableId: number, pageableType: string): any {
  const get = useGet();
  const getFunction = (pageableId: number, pageableType: string) => get(pageableId, pageableType);

  /**
   * Form store success function definition
   */
  const onSuccessUpdate = () => {
    showSuccessMessage("successfull_action");
    getFunction(pageableId, pageableType); // We pass the function to refresh the table on success
  };

  const update = useUpdate();
  const updateFunction = () => update(onSuccessUpdate);

  /** Edition element definition */
  const { element } = useContext(DetailsByPageableActionContext);

  /**
   * Form Reference definition
   */
  const formRef = createRef<JSONSchemaForm>();

  const onSubmitFunction = () =>
    formRef.current?.formElement.current.requestSubmit(); //We pass the function for the submit of the modal

  /**
   * Form configuration definition
   */
  const formConfig = {
    formRef: formRef, // We pass the ref to the form
    onSubmit: () => {
      updateFunction();
    },
    element: element, // We pass the element to the form in order to render it in the modal form
    context: DetailsByPageableActionContext,
    className: "page-form",
  };

  const actionTemplate = (
    <>
      <PageForm formConfig={formConfig} />
    </>
  );

  /** END EDIT FORM ACTION */

  return {
    getFunction,
    onSubmitFunction,
    actionTemplate,
  };
}

export default useDetailsByPageableAction;
