
const LabelEntryFormColsSchema = [
  [
    {
      name: "es",
      cols: "col-6"
    },
    {
      name: "en",
      cols: "col-6"
    },
    {
      name: "de",
      cols: "col-6"
    },
  ],
]

export default LabelEntryFormColsSchema;
