class SchemaHelper {
  public static filterByLanguage(schema: any, lang: string): any {
    const filteredSchema: any = Array.isArray(schema) ? [] : {};

    for (const key in schema) {
      if (schema.hasOwnProperty(key)) {
        const value = schema[key];

        if (SchemaHelper.isTranslatableProperty(value)) {
          // Conservar solo la clave del idioma especificado
          if (value[lang]) {
            filteredSchema[key] = { [lang]: value[lang] };
          }
        } else if (typeof value === "object") {
          filteredSchema[key] = SchemaHelper.filterByLanguage(value, lang);
        } else {
          filteredSchema[key] = value;
        }
      }
    }

    return filteredSchema;
  }

  private static isTranslatableProperty(obj: any): boolean {
    return (
      obj &&
      typeof obj === "object" &&
      Object.keys(obj).some((key) => SchemaHelper.isLanguageKey(key))
    );
  }

  private static isLanguageKey(key: string): boolean {
    // Aquí puedes ampliar la lógica para verificar si la clave es un código de idioma
    // Por ahora, solo comprobamos si es 'es', 'en', o 'de'
    return ["es", "en", "de"].includes(key);
  }
}

export default SchemaHelper;
