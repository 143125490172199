import { ObjectFieldTemplateProps } from "@rjsf/utils";

function ImgWysiwygLinkElementTemplate(props: ObjectFieldTemplateProps, func: any) {
  return (
    <>
      <div className="row">
      <div className="col-12">
          <div className="property-wrapper">
            {func("title", props)?.content}
          </div>
        </div>
        <div className="col-12">
          <div className="property-wrapper">
            {func("wysiwyg", props)?.content}
          </div>
        </div>
        <div className="col-12">
          <div className="property-wrapper">
            {func("img", props)?.content}
          </div>
        </div>
        <div className="col-12">
          <div className="property-wrapper">
            {func("link", props)?.content}
          </div>
        </div>
      </div>
    </>
  );
}

export default ImgWysiwygLinkElementTemplate;
