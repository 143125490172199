const EventRuleColsSchema = [
  [
    {
      name: "name",
      cols: "col-12",
    },
    {
      name: "weekday",
      cols: "col-6",
    },
    {
      name: "special_date",
      cols: "col-6",
    },
    {
      name: "time_start",
      cols: "col-6",
    },
    {
      name: "time_end",
      cols: "col-6",
    },
    {
      name: "event_rule_prices",
      cols: "col-12",
    },
  ],
];

export default EventRuleColsSchema;
