import { useContext } from "react";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import index from "@beeldit/core/services";
import { FiltersContext } from "../filters/FiltersContext";
import { SalesGraphContext } from "./SalesGraphContext";

function useSaleIndex() {
  const { setGraphData } = useContext(SalesGraphContext);
  const { tableFilters } = useContext(FiltersContext);
  const errorHandler = useErrorHandler();
  return () => {
    let filterPath = "measure=sales";
    if (tableFilters) {
      for (const [key, value] of Object.entries(tableFilters)) {
        if (value) {
          filterPath += `&${key}=${value}`;
        }
      }
    }
    index("graph", filterPath)
      .then((response: any) => {
        setGraphData(response.data);
      })
      .catch((error: any) => {
        errorHandler(error);
      });
  };
}

export default useSaleIndex;
