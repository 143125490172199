import EventPriceTypeSelector from "@main/event-price-types/components/EventPriceTypeSelector";
import EventSelector from "@main/events/components/EventSelector";

const filterUIConfig = {
  uiScheme: {
    classNames: "filters-container",
    event_id: {
      "ui:widget": "event-selector",
      "ui:options": {
        label: false, // Oculta el título del campo
      },
    },
    event_price_type_id: {
      "ui:widget": "event-price-type-selector",
      "ui:options": {
        label: false, // Oculta el título del campo
      },
    },
    search: {
      "ui:options": {
        label: false, // Oculta el título del campo
        placeholder: "Buscar...",
      },
    },
  },
  widgets: {
    "event-selector": EventSelector,
    "event-price-type-selector": EventPriceTypeSelector,
  },
};
const getFilterUIConfig: any = (type: string) => {
  return filterUIConfig;
};
export default getFilterUIConfig;
