import { RowAction } from "@beeldit/core/enums/RowAction.enum";
import { FinalTableConfig } from "@beeldit/core/models/FinalTableConfig.interface";
import { Eye } from "@phosphor-icons/react";

const tableConfig: FinalTableConfig = {
  index: null,
  actions: [],
  rowActions: [
    {
      name: RowAction.DETAILS_ON_MODAL,
      label: "details",
      icon: <Eye size={20} />,
      allowed: true,
      show: true,
      action: (element: any) => {
        console.log("details", element);
      },
    }
  ],
  rowConfiguration: {
    class: (element: any) => {
      return null;
    },
  },
  columns: [
    {
      name: "id",
      label: "#",
      key: "id",
      type: "number",
      show: true,
      sortable: true,
    },
    {
      name: "log_name",
      label: "log_name",
      key: "log_name",
      type: "string",
      show: true,
      sortable: true,
    },
    {
      name: "description",
      label: "description",
      key: "description",
      type: "string",
      show: true,
      sortable: true,
    },
  ],
  pagination: {
    enabled: true,
    back: true,
  },
};
const getConfig: any = (functions: any) => {
  const {
    indexFunction,
    getFunction
  } = functions;
  tableConfig.index = () => indexFunction();
  tableConfig.rowActions[0].action = (id: any) => getFunction(id);
  return tableConfig;
};
export default getConfig;
