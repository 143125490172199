import React, { useContext, useEffect, useState } from "react";

import InvoicesPage from "../pages/InvoicesPage";
import { ListActionProvider } from "../actions/list/ListActionContext";
import { RemoveActionProvider } from "../actions/remove/RemoveActionContext";
import { CreateActionProvider } from "../actions/create/CreateActionContext";
import { GeneratePDFProvider } from "../actions/generate-pdf/GeneratePDFContext";
import { GenerateFacturaeProvider } from "../actions/generate-facturae/GenerateFacturaeContext";
import { EditActionProvider } from "../actions/edit/EditActionContext";
import InvoiceAccountSelector from "../../invoice-accounts/components/InvoiceAccountSelector";
import { TenantAndCommerceContext } from "@main/contexts/TenantAndCommerceContext";
import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";

export default function InvoiceRouterWrapper(props: {
  config?: any;
  filters?: any;
  key?: string;
  formDefaultValues?: any;
  formFilterValues?: any;
  customFormWidgets?: any;
}) {
  const {
    config,
    filters,
    key,
    formDefaultValues,
    formFilterValues,
    customFormWidgets,
  } = props;

  const { commerce } = useContext(TenantAndCommerceContext);
  const { user } = useContext(AuthContext);

  const [invoiceConfiguration, setInvoiceConfiguration] = useState(null);
  const [formDefaultValuesAux, setFormDefaultValuesAux] = useState<{
    issuer_account?: any;
    receiver_account?: any;
  }>({ issuer_account: null, receiver_account: null });
  const [showReceiverAccountSelector, setShowReceiverAccountSelector] =
    useState(true);

  const formWidgets = {
    ...{ receiver_accountable_id: InvoiceAccountSelector },
    ...customFormWidgets,
  };

  useEffect(() => {
    if (commerce && user) {
      // Si user.role == 'admin' metemos los datos del comercio en el issuer_account
      // Si user.role == 'guide' metemos los datos del usuario en el receiver_account
      const accountData = {
        entity_id: commerce.id,
        entity_type: "App\\Models\\Commerce",
      };
      if (user.role === "admin") {
        setInvoiceConfiguration(commerce.invoice_configuration);
        setFormDefaultValuesAux({
          issuer_account: accountData,
        });
      } else if (user.role === "guide") {
        setShowReceiverAccountSelector(false);
        setInvoiceConfiguration(user.extra_data.invoice_configuration);
        setFormDefaultValuesAux({
          issuer_account: {
            entity_id: user.extra_data.invoice_account.entity_id,
            entity_type: user.extra_data.invoice_account.entity_type,
          },
          receiver_account: accountData,
        });
      }
    }
  }, [commerce, user]);

  return (
    <GeneratePDFProvider>
      <GenerateFacturaeProvider>
        <ListActionProvider key={key}>
          <RemoveActionProvider>
            <CreateActionProvider>
              <EditActionProvider>
                <InvoicesPage
                  formWidgets={formWidgets}
                  invoiceConfiguration={invoiceConfiguration}
                  formDefaultValues={formDefaultValuesAux}
                  config={config}
                  filters={filters}
                  formFilterValues={formFilterValues}
                  showReceiverAccountSelector={showReceiverAccountSelector}
                />
              </EditActionProvider>
            </CreateActionProvider>
          </RemoveActionProvider>
        </ListActionProvider>
      </GenerateFacturaeProvider>
    </GeneratePDFProvider>
  );
}
