import { useContext } from "react";
import { GenerateEventSessionsContext } from "./GenerateEventSessionsContext";
import { GenerateEventSessionsModel } from "./GenerateEventSessionsModel";

function useInitializeGenerateEventSessions() {
  const {setElement, setShowModal, setModelId} = useContext(GenerateEventSessionsContext);
  return (id: number) => {
    setModelId(id);
    setElement(new GenerateEventSessionsModel());
    setShowModal(true);
  }
}

export default useInitializeGenerateEventSessions;