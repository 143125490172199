import { useContext } from "react";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import store from "@beeldit/core/services/store";
import { CreateActionContext } from "./CreateActionContext";

function useStore() {
  const { element, setBackendFormErrors } = useContext(CreateActionContext);
  const errorHandler = useErrorHandler();
  return (onSuccess: any) => {
    store("users", element)
      .then((response: any) => {
        onSuccess(response.data);
      })
      .catch((error: any) => {
        console.error("Error fetching data:", error);
        errorHandler(error, setBackendFormErrors);
      });
  };
}

export default useStore;
