import { useEffect } from "react";
import { t } from "i18next";
import { Button, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useFillAction from "../actions/fill/useFillAction";

function FillInvoiceAccountPage(props: any) {
  let { token } = useParams();

  if (!token) {
    token = "0";
  }

  const {
    getFunction,
    onSubmitFunction,
    actionTemplate: detailsActionTemplate,
  } = useFillAction(token);

  useEffect(() => {
    getFunction(token);
  }, [token]);

  return (
    <div className="container">
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between ps-3 bg-white">
          <span>{t("invoice_account")}</span>
        </Card.Header>

        <Card.Body>
          {detailsActionTemplate}
          <div className="text-end">
            <Button variant="primary" onClick={onSubmitFunction}>
              {t("save_changes")}
            </Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default FillInvoiceAccountPage;
