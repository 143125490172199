import { RJSFSchema, UiSchema } from '@rjsf/utils';
import { customizeValidator } from '@rjsf/validator-ajv8';
import JSONSchemaForm from '@rjsf/core';
import { useTranslation } from "react-i18next";
import { MouseEventHandler, useContext, useEffect, useState } from 'react';
import customFormats from '@beeldit/core/custom-validations/custom-validation';
import useProcessSchema from '@beeldit/core/hooks/useProcessSchema';
import widgets from "@beeldit/core/widgets/widgets";

interface Prop {
    formConfig: {
        formRef: any,
        onSubmit: any,
        context: any,
    },
}

interface Context {
  element: any;
  setElement: (element: any) => MouseEventHandler;
  backendFormErrors: any;
}

function AddressForm(props: Prop) {

    const {formRef, onSubmit, context} = props.formConfig;    
    const { element, setElement, backendFormErrors } = useContext<Context>(context);

    const { t } = useTranslation();

    const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});

    const translations = {
      name: t('name'),
      street: t('street'),
      city: t('city'),
      state: t('state_province'),
      postal_code: t('postal_code'),
      country: t('country'),
      longitude: t('longitude'),
      latitude: t('latitude'),
      google_maps_link: t('google_map_link'),

    }
  
    let schema: RJSFSchema = {
      type: 'object',
      // required: ['minutes', 'customer_id', 'task', 'date', 'report_id'],
      properties: {
        name: {
          type: 'string',
          title: translations.name
        },
        street: {
          type: 'string',
          title: translations.street
        },
        city: {
          type: 'string',
          title: translations.city
        },
        state: {
          type: 'string',
          title: translations.state
        },
        country: {
          type: 'string',
          title: translations.country
        },
        postal_code: {
          type: 'string',
          title: translations.postal_code
        },
        longitude: {
          type: 'number',
          title: translations.longitude
        },
        latitude: {
          type: 'number',
          title: translations.latitude
        },
        google_maps_link: {
          type: 'string',
          title: translations.google_maps_link
        },
      },      
    };
    const uiSchema: UiSchema = {
      "ui:submitButtonOptions": {
        "props": {
          "disabled": false,
          "className": "btn btn-info"
        },
        "norender": true,
      }
    };
    const customWidgets = widgets;
    const handleChange = (type: any) => { /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
      // Actualizar el estado solo si no hay errores de validación
      setElement(type.formData);
      return console.log.bind(console, type)
    };
    const log = (type: any) => {
      return console.log.bind(console, type)
    };

    const formats = customFormats;  
    const validator = customizeValidator({ customFormats: formats });

    const processSchemaFunction = useProcessSchema();

    useEffect(() => {
        setFinalSchema(processSchemaFunction(schema, element));
    }, [element])

    return (
      <JSONSchemaForm
        noHtml5Validate
        showErrorList={false}
        ref={formRef}
        schema={schema}
        uiSchema={uiSchema}
        widgets={customWidgets}
        formData={element}
        validator={validator}
        onChange={handleChange}
        onSubmit={onSubmit}
        onError={log('errors')}
        extraErrors={backendFormErrors}
      />            
    )
}

export default AddressForm;
