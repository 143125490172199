import React, { useState, useEffect } from "react";
import { Feature } from "../models/Feature";
import Select, { ActionMeta, MultiValue } from "react-select";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";

import index from "@beeldit/core/services";

interface Option {
  label: string;
  value: number | string;
}

function MultiSelector(props: any) {
  const { id, value, required, disabled, readonly, onChange } = props;
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<Option>>(
    []
  );
  const [options, setOptions] = useState<Option[]>([]);
  const [elementsData, setElementsData] = useState({ elements: [] });

  const errorHandler = useErrorHandler();

  useEffect(() => {
    index("features")
      .then((response: any) => {
        setElementsData(response.data);
      })
      .catch((error: any) => {
        errorHandler(error, null);
      });
  }, []);

  useEffect(() => {
    const options = elementsData.elements.map((feature: Feature) => {
      return {
        label: feature.name,
        value: feature.id,
      };
    });
    setOptions(options);
    const currentSelectedOptions = options.filter((option) => {
      if (value && value.includes(option.value)) {
        return true;
      }
    });
    setSelectedOptions(currentSelectedOptions);
  }, [elementsData]);

  const handleOnChange = (
    newValue: MultiValue<Option>,
    actionMeta: ActionMeta<Option>
  ) => {
    setSelectedOptions(newValue);
    const model = newValue.map((option) => {
      return option.value;
    });
    onChange(model);
  };

  const selectStyles = {
    control: (provided: any) => ({
      ...provided,
      borderColor: "#E7F5F2",
      backgroundColor: "#E7F5F2",
      borderRadius: "0.25rem",
    }),
  };

  return (
    <Select
      id={id}
      isMulti
      options={options}
      value={selectedOptions}
      required={required}
      isDisabled={disabled || readonly}
      onChange={handleOnChange}
      styles={selectStyles}
    />
  );
}

function FeatureMultiSelector(props: any) {
  const { id, options, value, required, disabled, readonly, onChange } = props;
  return (
    <MultiSelector
      id={id}
      options={options.enumOptions}
      value={value}
      required={required}
      disabled={disabled}
      readonly={readonly}
      onChange={onChange}
    />
  );
}

export default FeatureMultiSelector;
