const filterConfig = {
  type: 'object',
  properties: {      
        role: { 
            type: 'string', 
        },
        search: {
            type: 'string',
        },
    }
}
const getFiltersConfig: any = (type: string) => {
  return filterConfig;
}
export default getFiltersConfig;