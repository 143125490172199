import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MouseEventHandler, useContext } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

interface Context {
  showDeleteConfirmationModal: boolean;
  setShowDeleteConfirmationModal: (showModal: boolean) => MouseEventHandler;
}
interface ModalConfig {
  context: any;
  onSubmit: any;
}

interface Props {
  modalConfig: ModalConfig;
  children: React.ReactNode;
}

function CrudModuleFactoryConfirmationModal(props: Props) {
  const { context, onSubmit } = props.modalConfig;
  const { children } = props;
  const { showDeleteConfirmationModal, setShowDeleteConfirmationModal } =
    useContext<Context>(context);
  const handleClose = () => setShowDeleteConfirmationModal(false); // We pass the function to close the modal on success
  const closeIcon = icon({ name: "xmark", style: "solid" })

  return (
    <>
      <Modal show={showDeleteConfirmationModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
          <a
            href="javascript:;"
            onClick={() => {
                setShowDeleteConfirmationModal(false);
            }}
          >
            <FontAwesomeIcon
              icon={closeIcon}
              className="ms-3 me-2 text-white"
            />
          </a>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CrudModuleFactoryConfirmationModal;
