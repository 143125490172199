export class GenerateAssignmentsModel {
    public id: number;
    public created_at: string;
    public updated_at: string;
    public deleted_at: string;

    constructor() {
        this.id = 0;
        this.created_at = '';
        this.updated_at = '';
        this.deleted_at = '';
    }
}