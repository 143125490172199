import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import { getApi } from "@beeldit/core/helpers/api";

function useGetConfiguration() {
  const errorHandler = useErrorHandler();

  return (
    configurationKey: string,
    onSuccess: any = (data: any) => {
      return data;
    }
  ) => {
    getConfiguration(configurationKey)
      .then((response: any) => {
        let data = response.data;
        onSuccess(data);
      })
      .catch((error: any) => {
        errorHandler(error);
      });
  };
}

async function getConfiguration(configurationKey: string) {
  // Comprueba si existe en localstorage y no está caducado
  if (localStorage.getItem(configurationKey)) {
    const item = JSON.parse(localStorage.getItem(configurationKey) as string);
    if (item.expiration > new Date().getTime()) {
      return item.value;
    }
  }
  return await getApi().get(`/configurations/${configurationKey}`);
}


export default useGetConfiguration;
