import { GenerateEventSessionsContext } from "./GenerateEventSessionsContext";
import useInitialize from "./useInitialize";
import useExecute from "./useExecute";
import { createRef, useContext } from "react";
import JSONSchemaForm from "@rjsf/core";
import CrudModuleFactoryModal from "@beeldit/core/components/CrudModuleFactoryModal";
import { t } from "i18next";
import EventSessionGenerateForm from "@main/events/components/EventSessionGenerateForm";

function useGenerateEventSessionsAction(index: any): any {
  const initialize = useInitialize();
  const initializeFunction = (id: number) => initialize(id);

  const onSuccessExecute = () => {
    setShowModal(false);
  };
  
  const execute = useExecute();  

  const executeFunction = () => execute(onSuccessExecute);

  const { setShowModal } = useContext(GenerateEventSessionsContext);

  const formRef = createRef<JSONSchemaForm>();

  const { element } = useContext(GenerateEventSessionsContext);

  const modalConfig = {
    context: GenerateEventSessionsContext,
    title: t("generate_event_sessions"),
    onSubmit: () => executeFunction(),
  };

    /**
   * Form configuration definition
   */
    const formConfig = {
      formRef: formRef, // We pass the ref to the form
      onSubmit: () => {
        executeFunction();
      },
      element: element, // We pass the element to the form in order to render it in the modal form
      context: GenerateEventSessionsContext,
    };

  const actionTemplate = (
    <CrudModuleFactoryModal modalConfig={modalConfig}>
      <EventSessionGenerateForm formConfig={formConfig} /> 
      {t("are_you_sure_you_want_to_generete_the_sessions_of_this_events?")}
    </CrudModuleFactoryModal>
  );

  /** END EDIT TABLE ACTION */

  return {
    initializeFunction,
    actionTemplate,
  };
}

export default useGenerateEventSessionsAction;
