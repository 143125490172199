const EventSessionColsSchema = [
  [
    {
      name: "event_id",
      cols: "col-12",
    },
    {
      name: "date",
      cols: "col-6",
    },
    {
      name: "event_rule_id",
      cols: "col-6",
    },
    {
      name: "time_start",
      cols: "col-6",
    },
    {
      name: "time_end",
      cols: "col-6",
    },
    {
      name: "prices",
      cols: "col-12",
    },
  ],
];

export default EventSessionColsSchema;
