import { useContext, useEffect, useState } from "react";
import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { t } from "i18next";
import { Button, Card, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useDetailsAction from "../actions/details/useDetailsAction";
import EventSessionsPage from "@main/event-sessions/pages/EventSessionsPage";
import { ListActionProvider as EventSessionListActionProvider } from "@main/event-sessions/actions/list/ListActionContext";
import { CreateActionProvider as EventSessionCreateActionProvider } from "@main/event-sessions/actions/create/CreateActionContext";
import { RemoveActionProvider as EventSessionRemoveActionProvider } from "@main/event-sessions/actions/remove/RemoveActionContext";
import { EditActionProvider as EventSessionEditActionProvider } from "@main/event-sessions/actions/edit/EditActionContext";
import { LockEventSessionsProvider } from "@main/event-sessions/actions/lock-event-session/LockEventSessionsContext";
import { UnlockEventSessionsProvider } from "@main/event-sessions/actions/unlock-event-session/UnlockEventSessionsContext";

import { ListActionProvider as EventRuleListActionProvider } from "@main/event-rules/actions/list/ListActionContext";
import { CreateActionProvider as EventRuleCreateActionProvider } from "@main/event-rules/actions/create/CreateActionContext";
import { RemoveActionProvider as EventRuleRemoveActionProvider } from "@main/event-rules/actions/remove/RemoveActionContext";
import { EditActionProvider as EventRuleEditActionProvider } from "@main/event-rules/actions/edit/EditActionContext";
import EventRulesPage from "@main/event-rules/pages/EventRulePage";

import { ListActionProvider as EventBlockingRuleListActionProvider } from "@main/event-blocking-rules/actions/list/ListActionContext";
import { CreateActionProvider as EventBlockingRuleCreateActionProvider } from "@main/event-blocking-rules/actions/create/CreateActionContext";
import { RemoveActionProvider as EventBlockingRuleRemoveActionProvider } from "@main/event-blocking-rules/actions/remove/RemoveActionContext";
import { EditActionProvider as EventBlockingRuleEditActionProvider } from "@main/event-blocking-rules/actions/edit/EditActionContext";
import EventBlockingRulesPage from "@main/event-blocking-rules/pages/EventBlockingRulePage";

import { ListActionProvider as BookingListActionProvider } from "@main/bookings/actions/list/ListActionContext";
import { CreateActionProvider as BookingCreateActionProvider } from "@main/bookings/actions/create/CreateActionContext";
import { RemoveActionProvider as BookingRemoveActionProvider } from "@main/bookings/actions/remove/RemoveActionContext";
import { EditActionProvider as BookingEditActionProvider } from "@main/bookings/actions/edit/EditActionContext";
import BookingsPage from "@main/bookings/pages/BookingsPage";
import { DetailsByPageableActionProvider } from "@bloonde/cms/cms-pages/actions/detailsByPageable/DetailsByPageableActionContext";
import PageEditionByPageablePage from "@bloonde/cms/cms-pages/pages/PageEditionByPageablePage";
import { DetailsActionContext } from "../actions/details/DetailsActionContext";

function EventDashboardPage() {
  const { element: event } = useContext(DetailsActionContext);

  let { eventId } = useParams();

  if (!eventId) {
    eventId = "0";
  }

  const {
    getFunction,
    onSubmitFunction,
    actionTemplate: detailsActionTemplate,
  } = useDetailsAction(parseInt(eventId));

  useEffect(() => {
    getFunction(eventId);
  }, [eventId]);

  /** Filtro con el que inicializamos las tablas asociadas al comercio en el que nos encontramos */
  const filters = { event_id: parseInt(eventId) };
  const formDefaultValues = { event_id: parseInt(eventId) };
  const formFilterValues = { event_id: parseInt(eventId) };

  const [configurationTabLoaded, setConfigurationTabLoaded] = useState(false);

  const onSelectTab = (key: string) => {
    if (key === "configuration") {
      setConfigurationTabLoaded(true);
    }
  };

  return (
    <>
      <Tabs
        defaultActiveKey="content"
        id="uncontrolled-tab-example"
        className=""
        onSelect={(key: any) => {
          onSelectTab(key);
        }}
      >
        <Tab eventKey="content" title={t("content")}>
          <DetailsByPageableActionProvider>
            <PageEditionByPageablePage
              pageableId={parseInt(eventId)}
              pageableType="App\Models\Event"
            />
          </DetailsByPageableActionProvider>
        </Tab>
        <Tab eventKey="configuration" title={t("configuration")}>
          {configurationTabLoaded && (
            <div className="row">
              <div className="col-xl-6 col-12">
                <div className="mb-5">
                  <Card>
                    <Card.Header className="d-flex align-items-center justify-content-between ps-3 bg-white">
                      <span>{t("event")}</span>
                    </Card.Header>

                    <Card.Body>
                      {detailsActionTemplate}
                      <div className="text-end">
                        <Button variant="primary" onClick={onSubmitFunction}>
                          {t("save_changes")}
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
                {event && !event.external && (
                  <>
                    <div className="mb-5">
                      <EventSessionListActionProvider>
                        <EventSessionRemoveActionProvider>
                          <EventSessionCreateActionProvider>
                            <EventSessionEditActionProvider>
                              <LockEventSessionsProvider>
                                <UnlockEventSessionsProvider>
                                  <EventSessionsPage
                                    filters={filters}
                                    formDefaultValues={formDefaultValues}
                                    formFilterValues={formFilterValues}
                                  />
                                </UnlockEventSessionsProvider>
                              </LockEventSessionsProvider>
                            </EventSessionEditActionProvider>
                          </EventSessionCreateActionProvider>
                        </EventSessionRemoveActionProvider>
                      </EventSessionListActionProvider>
                    </div>
                  </>
                )}
              </div>
              <div className="col-xl-6 col-12">
                {event && !event.external && !event.unique_date &&(
                  <>
                    <div className="mb-5">
                      <EventRuleListActionProvider>
                        <EventRuleRemoveActionProvider>
                          <EventRuleCreateActionProvider>
                            <EventRuleEditActionProvider>
                              <EventRulesPage
                                filters={filters}
                                formDefaultValues={formDefaultValues}
                              />
                            </EventRuleEditActionProvider>
                          </EventRuleCreateActionProvider>
                        </EventRuleRemoveActionProvider>
                      </EventRuleListActionProvider>
                    </div>
                  </>
                )}

                {event && !event.external && !event.unique_date && (
                  <>
                    <div className="mb-5">
                      <EventBlockingRuleListActionProvider>
                        <EventBlockingRuleRemoveActionProvider>
                          <EventBlockingRuleCreateActionProvider>
                            <EventBlockingRuleEditActionProvider>
                              <EventBlockingRulesPage
                                filters={filters}
                                formDefaultValues={formDefaultValues}
                              />
                            </EventBlockingRuleEditActionProvider>
                          </EventBlockingRuleCreateActionProvider>
                        </EventBlockingRuleRemoveActionProvider>
                      </EventBlockingRuleListActionProvider>
                    </div>
                  </>
                )}
                {event && !event.external && !event.without_registration && (
                  <div className="mb-5">
                    <BookingListActionProvider>
                      <BookingRemoveActionProvider>
                        <BookingCreateActionProvider>
                          <BookingEditActionProvider>
                            <BookingsPage
                              filters={filters}
                              formDefaultValues={formDefaultValues}
                              formFilterValues={formFilterValues}
                            />
                          </BookingEditActionProvider>
                        </BookingCreateActionProvider>
                      </BookingRemoveActionProvider>
                    </BookingListActionProvider>
                  </div>
                )}
              </div>
            </div>
          )}
        </Tab>
      </Tabs>
    </>
  );
}

export default EventDashboardPage;
