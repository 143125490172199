import { RemoveActionContext } from "./RemoveActionContext";
import usePrepareRemove from "./usePrepareRemove";
import useRemove from "./useRemove";
import { useContext } from "react";
import CrudModuleFactoryModal from "@beeldit/core/components/CrudModuleFactoryModal";
import { t } from "i18next";

function useRemoveAction(index: any): any {
  const prepareRemove = usePrepareRemove();
  const prepareRemoveFunction = (id: any) => prepareRemove(id);

  const onSuccessRemove = () => {
    setShowModal(false);
    index();
  };
  
  const remove = useRemove();  

  const removeFunction = () => remove(onSuccessRemove);

  const { setShowModal } = useContext(RemoveActionContext);
  
  const modalConfig = {
    context: RemoveActionContext,
    title: "remove_room",
    onSubmit: () => removeFunction(),
  };

  const actionTemplate = (
    <CrudModuleFactoryModal modalConfig={modalConfig}>      
      {t("are_you_sure_you_want_to_remove_this_room")}
    </CrudModuleFactoryModal>
  );

  /** END EDIT TABLE ACTION */

  return {
    prepareRemoveFunction,
    actionTemplate,
  };
}

export default useRemoveAction;
