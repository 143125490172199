import { useContext } from "react";

import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import update from "@beeldit/core/services/update";
import { EditActionContext } from "./EditActionContext";

function useUpdate() {
  const { element, setBackendFormErrors } = useContext(EditActionContext);
  const errorHandler = useErrorHandler();

  return (onSuccess: any) => {
    update("invoice_accounts", element.id, element)
      .then((response: any) => {
        onSuccess(response.data);
      })
      .catch((error: any) => {
        errorHandler(error, setBackendFormErrors);
      });
  };
}

export default useUpdate;
