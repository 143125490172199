import { MouseEventHandler, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

interface Context {
  setFiltersLoaded: (tableData: any) => MouseEventHandler;
  setTableFilters: (tableData: any) => MouseEventHandler;
}

function useFilters(filters: any, contextClass: any) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { setFiltersLoaded } = useContext<Context>(contextClass);
  const { setTableFilters } = useContext<Context>(contextClass);

  useEffect(() => {
    for (const [key, value] of queryParams.entries()) {
      if (filters[key] !== undefined) {
        const parsedValue = parseInt(value);
        if (!isNaN(parsedValue)) {
          filters[key] = parsedValue;
        } else {
          filters[key] = value;
        }
      }
    }

    setTableFilters(filters);
    setFiltersLoaded(true);
  }, []);
}

export function initFilters(filters: any, location: any) {
  const queryParams = new URLSearchParams(location.search);

  for (const [key, value] of queryParams.entries()) {
    if (filters[key] !== undefined) {
      // Si es una fecha (contiene dos - o dos /) la almacenamos tal cual
      if (value.includes("-") || value.includes("/")) {
        filters[key] = value;
      } else {
        const parsedValue = parseInt(value);
        if (!isNaN(parsedValue)) {
          filters[key] = parsedValue;
        } else {
          filters[key] = value;
        }
      }
    }
  }
  return filters;  
}

export default useFilters;
