import { createRef, useContext } from "react";
import JSONSchemaForm from "@rjsf/core";
import useGet from "./useGet";
import useUpdate from "./useUpdate";
import { FillActionContext } from "./FillActionContext";
import { InvoiceAccount } from "../../models/InvoiceAccount";
import InvoiceAccountForm from "../../components/InvoiceAccountForm";
import FillInvoiceAccountForm from "../../components/FillInvoiceAccountForm";
import { showSuccessMessage } from "@beeldit/core/helpers/messagesHelper";

function useFillAction(token: string): any {

  const get = useGet();
  const getFunction = (token: string) => get(token);

  /**
   * Form store success function definition
   */
  const onSuccessUpdate = () => {    
    getFunction(token); // We pass the function to refresh the table on success
    showSuccessMessage("fill_invoice_account_success")    
  };

  const update = useUpdate();
  const updateFunction = () => update(token, onSuccessUpdate);
  
  /** Edition element definition */
  const { element } = useContext(FillActionContext);

  /**
   * Form Reference definition
   */
  const formRef = createRef<JSONSchemaForm>();  

  const onSubmitFunction = () => formRef.current?.formElement.current.requestSubmit() //We pass the function for the submit of the modal

  /**
   * Form configuration definition
   */
  const formConfig = {
    formRef: formRef, // We pass the ref to the form
    onSubmit: () => {
      updateFunction();
    },
    element: element, // We pass the element to the form in order to render it in the modal form
    context: FillActionContext,
  };

  const actionTemplate = (
    <FillInvoiceAccountForm formConfig={formConfig} />
  );

  /** END EDIT FORM ACTION */

  return {
    getFunction,
    onSubmitFunction,
    actionTemplate,
  };
}

export default useFillAction;
