import { useContext } from "react";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import get from "@beeldit/core/services/get";
import { DetailsActionContext } from "./DetailsActionContext";
import {useTranslation} from "react-i18next";

function useGet() {
  const { setElement, setSelectedLang } = useContext(DetailsActionContext);
  const errorHandler = useErrorHandler();
  const { t } = useTranslation();

  return (
    id: any,
    onSuccess: any = (data: any) => {
      return data;
    }
  ) => {
    get("pages", id,'?relations=seo,sections,tags&appends=tag_ids')
      .then((response: any) => {
        let data = response.data;
        data = onSuccess(response.data);
        setElement(data); 
        setSelectedLang("es");
      })
      .catch((error: any) => {
        errorHandler(error);
      });
  };
}

export default useGet;
