import { t, use } from "i18next";
import { ButtonGroup, Card, ToggleButton } from "react-bootstrap";
import useFiltersAction from "../graphs/filters/useFiltersAction";
import useSalesGraph from "../graphs/sales/useSalesGraph";
import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { useContext, useState } from "react";
import useTicketsGraph from "../graphs/tickets/useTicketsGraph";
import useVisitsGraph from "../graphs/visits/useVisitsGraph";
import useBillingGraph from "../graphs/billing/useBillingGraph";

function ReportsPage(props: any) {
  const { user } = useContext(AuthContext);

  const { actionTemplate } = useFiltersAction();

  const { graphTemplate: salesGraphTemplate } = useSalesGraph(user);
  const { graphTemplate: ticketsGraphTemplate } = useTicketsGraph(user);
  const { graphTemplate: billingGraphTemplate } = useBillingGraph(user);
  const { graphTemplate: visitsGraphTemplate } = useVisitsGraph(user);

  const [graphToShow, setGraphToShow] = useState("sales");

  const graphTypes = [
    { name: "sales", value: "sales" },
    { name: "tickets", value: "tickets" },
    { name: "visits", value: "visits" },
    { name: "billing", value: "billing" },
  ];

  return (
    <Card>
      <Card.Header className="d-flex align-items-center justify-content-between ps-3 bg-white">
        <span>{t("reports")}</span>
      </Card.Header>
      <Card.Body>
        {actionTemplate}
        <hr></hr>
        <div className="report">
          <ButtonGroup className="mb-2">
            {graphTypes.map((graphType, idx) => (
              <ToggleButton
                className="report-radio-button"
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant="primary"
                name="radio"
                value={graphType.value}
                checked={graphToShow === graphType.value}
                onChange={(e) => setGraphToShow(e.currentTarget.value)}
              >
                {t(graphType.name)}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>
        <div className="mt-5">
          {graphToShow == "sales" && salesGraphTemplate}
          {graphToShow == "tickets" && ticketsGraphTemplate}
          {graphToShow == "visits" && visitsGraphTemplate}
          {graphToShow == "billing" && billingGraphTemplate}
        </div>
      </Card.Body>
    </Card>
  );
}

export default ReportsPage;
