const BookingColsSchema = [
  [
    {
      name: "event_session_id",
      cols: "col-8",
    },
    {
      name: "status_id",
      cols: "col-4",
    },
    {
      name: "total_price",
      cols: "col-4",
    },
    {
      name: "tickets",
      cols: "col-12",
    },
    {
      name: "invoice_account",
      cols: "col-12",
    },    
  ],
];

export default BookingColsSchema;
