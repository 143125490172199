import React, { createContext, useState } from "react";

interface VisitsGraphContextType {
  graphData: any[];
  setGraphData: (graphData: any[]) => void;
}

export const VisitsGraphContext = createContext<VisitsGraphContextType>({
  graphData: [],
  setGraphData: () => {},
});

interface VisitsGraphProviderProps {
  children: React.ReactNode;
}

export const VisitsGraphProvider: React.FC<VisitsGraphProviderProps> = ({
  children,
}) => {
  const [graphData, setGraphData] = useState<any[]>();

  const value = {
    graphData,
    setGraphData,
  };
  return (
    <VisitsGraphContext.Provider value={value}>
      {children}
    </VisitsGraphContext.Provider>
  );
};
