import { useContext } from "react";
import { CreateActionContext } from "./CreateActionContext";
import init from "@beeldit/core/services/init";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";

function useInitialize() {
  const { setElement, setShowModal } = useContext(CreateActionContext);
  const errorHandler = useErrorHandler();
  return (formDefaultValues: any) => {
    init("event-blocking-rules", '?event_id=' + formDefaultValues.event_id)
      .then((response: any) => {
        let data = response.data;
        setElement({
            ...data, 
            ...formDefaultValues
          });
        setShowModal(true);
      })
      .catch((error: any) => {
        errorHandler(error);
      });
  };
}

export default useInitialize;
