import React, { createContext, useState } from "react";

interface DetailsActionContextType {  
  backendFormErrors: any;
  setBackendFormErrors: (backendFormErrors: any) => void;    
  element: any;
  setElement: (element: any) => void;  
}

export const DetailsActionContext = createContext<DetailsActionContextType>({  
  backendFormErrors: {},
  setBackendFormErrors: () => {},  
  element: null,
  setElement: () => {},  
});

interface DetailsActionProviderProps {
  children: React.ReactNode;
}

export const DetailsActionProvider: React.FC<DetailsActionProviderProps> = ({
  children,
}) => {  
  const [backendFormErrors, setBackendFormErrors] = useState(null);
  const [element, setElement] = useState<any>(null);  

  const value = {    
    backendFormErrors,
    setBackendFormErrors,
    element,
    setElement,    
  };
  return (
    <DetailsActionContext.Provider value={value}>      
      {children}
    </DetailsActionContext.Provider>
  );
};
