
const EntryFormColsSchema = [
  [
    {
      name: "label",
      cols: "col-12"
    }
  ],
  [
    {
      name: "page_id",
      cols: "col-6"
    },    
  ],
]

export default EntryFormColsSchema;
