import { useContext } from "react";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import { GenerateAssignmentsContext } from "./GenerateAssignmentsContext";
import execute from "@beeldit/core/services/execute";

function useExecuteGenerateAssignments() {
  const errorHandler = useErrorHandler();
  const { modelId, element, setBackendFormErrors } = useContext(
    GenerateAssignmentsContext
  );
  return (onSuccess: any) => {
    execute(`assignments/generate-assignments/${modelId}`, element)
      .then((response: any) => {
        onSuccess(response.data);
      })
      .catch((error: any) => {
        console.error("Error fetching data:", error);
        errorHandler(error, setBackendFormErrors);
      });
  };
}

export default useExecuteGenerateAssignments;
