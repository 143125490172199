import RoleSelector from "../components/RoleSelector";

const filterUIConfig = {
    uiScheme: {
        classNames: "filters-container",
        role: {
            "ui:widget": "role-selector",
            "ui:options": {
                label: false, // Oculta el título del campo
            }
        },        
        search: {
            "ui:options": {
                label: false, // Oculta el título del campo
                placeholder: "Buscar..."
            }
        },
    },
    widgets: {
        'role-selector': RoleSelector,
    }
}
const getFilterUIConfig: any = (type: string) => {
    return filterUIConfig;
}
export default getFilterUIConfig;