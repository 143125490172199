import React, { createContext, useState } from "react";

interface DetailsActionContextType {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  element: any;
  setElement: (element: any) => void;
  showFooter: boolean;
}

export const DetailsActionContext = createContext<DetailsActionContextType>({
  showModal: false,
  setShowModal: () => {},
  element: null,
  setElement: () => {},
  showFooter: false,
});

interface DetailsActionProviderProps {
  children: React.ReactNode;
}

export const DetailsActionProvider: React.FC<DetailsActionProviderProps> = ({
  children,
}) => {  
  const [showModal, setShowModal] = useState(false);
  const [element, setElement] = useState<any>(null);  
  const [showFooter] = useState<any>(false);

  const value = {
    showModal,
    showFooter,
    setShowModal,
    element,
    setElement,    
  };
  return (
    <DetailsActionContext.Provider value={value}>
      {children}
    </DetailsActionContext.Provider>
  );
};
