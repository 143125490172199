import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { RowAction } from "@beeldit/core/enums/RowAction.enum";
import { TableAction } from "@beeldit/core/enums/TableAction.enum";
import { FinalTableConfig } from "@beeldit/core/models/FinalTableConfig.interface";

const tableConfig: FinalTableConfig = {
      index: null,
      actions: [
        {
          name: TableAction.CREATE, 
          label: 'create',
          allowed: true,
          show: true,
          action: null,
          icon: icon({name: 'plus', style: 'solid'}),
        },
        {
          name: TableAction.REFRESH, 
          label: 'refresh',
          allowed: true,
          show: true,
          action: null,
          icon: icon({name: 'refresh', style: 'solid'}),
        },

      ],
      rowActions: [
        {
          name: RowAction.EDIT, 
          label: 'edit',
          icon: icon({name: 'pencil', style: 'solid'}),
          allowed: true,
          show: true,
          action: (element: any) => {
            console.log('edit', element);
          }
        },
        {
          name: RowAction.DELETE, 
          label: 'delete',
          icon: icon({name: 'trash', style: 'solid'}),
          allowed: true,
          show: true,
          action: (element: any) => { 
            console.log('delete', element);
          }              
        }
      ],
      rowConfiguration: {
        class: (element: any) => {
          return null;
        }
      },
      columns: [
        {
          name: 'id',
          label: '#',
          key: 'id',
          type: 'number',
          show: true,
          allowOrder: true,
        },
        {
          name: 'amount',
          label: 'amount',
          key: 'amount',
          type: 'number',
          show: true,
          allowOrder: true,
        },
        {
          name: 'event',
          label: 'event',
          key: 'event.name',
          type: 'string',
          show: true,
          allowOrder: true,
        },
        {
          name: 'event price type',
          label: 'event price type',
          key: 'event_price_type.name',
          type: 'string',
          show: true,
          allowOrder: true,
        },
  
      ],
      pagination: {
        enabled: true,
        back: true
    }
}
const getConfig: any = (functions: any) => {
    const { getFunction, indexFunction, initializeFunction, prepareRemoveFunction } = functions;
    tableConfig.actions[0].action = () => initializeFunction();
    tableConfig.actions[1].action = () => indexFunction();
    tableConfig.index = () => indexFunction();
    tableConfig.rowActions[0].action = (id: any) => getFunction(id);
    tableConfig.rowActions[1].action = (id: any) => prepareRemoveFunction(id);
    return tableConfig;
}
export default getConfig;