import { useContext } from "react";

import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import update from "@beeldit/core/services/update";
import { FillActionContext } from "./FillActionContext";

function useUpdate() {
  const { element, setBackendFormErrors } = useContext(FillActionContext);
  const errorHandler = useErrorHandler();

  return (token: any, onSuccess: any) => {
    update("public/invoice_accounts", token, element)
      .then((response: any) => {
        onSuccess(response.data);
      })
      .catch((error: any) => {
        errorHandler(error, setBackendFormErrors);
      });
  };
}

export default useUpdate;
