import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/bootstrap-4";
import { useTranslation } from "react-i18next";
import { MouseEventHandler, useContext, useEffect, useState } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import BeelditFileInput from "@beeldit/core/components/BeelditFileInput";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
    context: any;
  };
}

interface Context {
  element: any;
  setElement: (element: any) => MouseEventHandler;
  backendFormErrors: any;
}

function FillInvoiceAccountForm(props: Prop) {
  const { formRef, onSubmit, context } = props.formConfig;
  const { element, setElement, backendFormErrors } =
    useContext<Context>(context);

  const { t } = useTranslation();

  const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});

  const translations = {
    fiscal_name: t("Fiscal Name"),
    first_surname: t("Fiscal First Surname"),
    last_surname: t("Fiscal Last Surname"),
    fiscal_address: t("Fiscal Address"),
    fiscal_number: t("Fiscal Number"),
    fiscal_zipcode: t("Fiscal Zip Code"),
    fiscal_locality: t("Fiscal Locality"),
    fiscal_province: t("Fiscal Province"),
    fiscal_country_code: t("Fiscal Country Code"),
    is_legal_entity: t("Is Legal Entity?"),
    company_logo: t("Company Logo"),
    phone: t("Phone"),
    email: t("Email"),
    additional_information: t("Additional Information"),
    bank_information: t("Bank Information"),    
  };

  let schema: RJSFSchema = {
    type: "object",
    // required: ['minutes', 'customer_id', 'task', 'date', 'report_id'],
    properties: {
      fiscal_name: {
        type: "string",
        title: translations.fiscal_name,
      },
      fiscal_first_surname: {
        type: "string",
        title: translations.first_surname,
      },
      fiscal_last_surname: {
        type: "string",
        title: translations.last_surname,
      },
      fiscal_number: {
        type: "string",
        title: translations.fiscal_number,
      },
      fiscal_address: {
        type: "string",
        title: translations.fiscal_address,
      },
      fiscal_zip: {
        type: "string",
        title: translations.fiscal_zipcode,
      },
      fiscal_city: {
        type: "string",
        title: translations.fiscal_locality,
      },
      fiscal_province: {
        type: "string",
        title: translations.fiscal_province,
      },
      fiscal_country_code: {
        type: "string",
        title: translations.fiscal_country_code,
        default: "ES",
        oneOf: [
          {
            const: "ES",
            title: "Spain",
          },
          {
            const: "IT",
            title: "Italy",
          },
          // TODO lo ideal es traerlos de una api como    https://restcountries.com/v3.1/all
        ],
      },      
      phone: {
        type: "string",
        title: translations.phone,
      },
      email: {
        type: "string",
        title: translations.email,
      },
      company_logo: {
        type: "array",
        title: translations.company_logo,
        items: {
          type: "object",
          properties: {
            filename: { type: "string", title: "filename" },
            url: { type: "string", title: "url" },
          },
        },
      },
      additional_information: {
        type: "string",
        title: translations.additional_information,
        format: "textarea",
      },
      bank_information: {
        type: "string",
        title: translations.bank_information,
        format: "textarea",
      },
    },
  };
  const uiSchema: UiSchema = {
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
    company_logo: {
      "ui:widget": "file-input",
    },
  };
  const customWidgets = {
    ...widgets,
    ...{ "file-input": BeelditFileInput },
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  const processSchemaFunction = useProcessSchema();

  useEffect(() => {
    setFinalSchema(processSchemaFunction(schema, element));
  }, [element]);

  return (
    <JSONSchemaForm
      noHtml5Validate
      showErrorList={false}
      ref={formRef}
      schema={schema}
      uiSchema={uiSchema}
      widgets={customWidgets}
      formData={element}
      validator={validator}
      onChange={handleChange}
      onSubmit={onSubmit}
      onError={log("errors")}
      extraErrors={backendFormErrors}
    />
  );
}

export default FillInvoiceAccountForm;
