import { t } from "i18next";
import Noty from "noty";

// Crear una pila con los mensajes que se van mostrando. Cada mensaje tendrá un tipo, un código, un texto y un tiempo y la hora de creación.
const messagesStack: any[] = [];

// Creamos una función que limpie los mensajes que ya han pasado su tiempo de vida.
function cleanMessagesStack() {
  const currentTime = new Date().getTime();
  messagesStack.forEach((message, index) => {
    if (currentTime - message.creationTime > message.timeout) {
      messagesStack.splice(index, 1);
    }
  });
}

// Creamos una función que compruebe si existe o no un mensaje del mismo tipo y el mismo código
function messageExists(type: string) {
  return messagesStack.some((message) => message.type === type);
}

export function showSuccessMessage(message: string): void {
  cleanMessagesStack();
  if (messageExists("success")) {
    return;
  }
  new Noty({
    text: t(message) ?? "",
    theme: "relax",
    type: "success",
    layout: "topCenter",
    timeout: 6000,
  }).show();
}

export function showErrorMessage(message: string): void {
  cleanMessagesStack();
  if (messageExists("error")) {
    return;
  }
  new Noty({
    text: t(message) ?? "",
    theme: "relax",
    type: "error",
    layout: "topCenter",
    timeout: 6000,
  }).show();
}
