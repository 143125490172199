const BookingInvoiceAccountColsSchema = [
  [
    {
      name: "fiscal_name",
      cols: "col-6",
    },
    {
      name: "fiscal_first_surname",
      cols: "col-6",
    },
    {
      name: "fiscal_last_surname",
      cols: "col-6",
    },
    {
      name: "fiscal_number",
      cols: "col-6",
    },
    {
      name: "fiscal_address",
      cols: "col-8",
    },
    {
      name: "fiscal_zip",
      cols: "col-4",
    },
    {
      name: "fiscal_city",
      cols: "col-4",
    },
    {
      name: "fiscal_province",
      cols: "col-4",
    },
    {
      name: "fiscal_country_code",
      cols: "col-4",
    },
    {
      name: "phone",
      cols: "col-4",
    },
    {
      name: "email",
      cols: "col-4",
    },
  ],
];

export default BookingInvoiceAccountColsSchema;
