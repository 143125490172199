import { createRef, useContext } from "react";
import JSONSchemaForm from "@rjsf/core";
import CrudModuleFactoryModal from "@beeldit/core/components/CrudModuleFactoryModal";
import useGet from "./useGet";
import useUpdate from "./useUpdate";
import { EditActionContext } from "./EditActionContext";
import CouponForm from "../../components/CouponForm";

function useEditAction(index: any): any {
  const get = useGet();
  const getFunction = (id: number) => get(id);

  /**
   * Form store success function definition
   */
  const onSuccessUpdate = () => {
    setShowModal(false);
    index(); // We pass the function to refresh the table on success
  };

  const update = useUpdate();
  const updateFunction = () => update(onSuccessUpdate);

  /** Creation and edition modal function and state definition */
  const { showModal, setShowModal } = useContext(EditActionContext);

  /** Creation and edition modal element definition */
  const { element } = useContext(EditActionContext);

  /**
   * Form Reference definition
   */
  const formRef = createRef<JSONSchemaForm>();

  /**
   * Creation and edition modal configuration definition
   */
  const modalConfig = {
    title: "edit_coupon",
    context: EditActionContext,
    onSubmit: () => formRef.current?.formElement.current.requestSubmit(), //We pass the function for the submit of the modal
  };

  /**
   * Form configuration definition
   */
  const formConfig = {
    formRef: formRef, // We pass the ref to the form
    onSubmit: () => {
      updateFunction();
    },
    element: element, // We pass the element to the form in order to render it in the modal form
    context: EditActionContext,
    className: "coupon-form",
  };

  const actionTemplate = (
    <CrudModuleFactoryModal modalConfig={modalConfig}>
      <CouponForm formConfig={formConfig} />
    </CrudModuleFactoryModal>
  );

  /** END EDIT FORM ACTION */

  return {
    getFunction,
    actionTemplate,
  };
}

export default useEditAction;
