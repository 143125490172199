import { useContext, useEffect, useState } from "react";
import { t } from "i18next";
import { Button, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useDetailsAction from "../actions/details/useDetailsAction";
import TemplateSelector from "@bloonde/cms/cms-templates/components/TemplateSelector";

function PageEditionPage() {
  let { pageId } = useParams();

  if (!pageId) {
    pageId = "0";
  }

  const {
    getFunction,
    onSubmitFunction,
    actionTemplate: detailsActionTemplate,
  } = useDetailsAction(parseInt(pageId));

  useEffect(() => {
    getFunction(pageId);
  }, [pageId]);

  const filters = { page_id: pageId };
  const formFilterValues = { page_id: pageId };
  const formDefaultValues = { page_id: pageId };
  const formWidgets = { reviewer_id: TemplateSelector };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="mb-5">
            <Card>
              <Card.Header className="d-flex align-items-center justify-content-between ps-3 bg-white">
                <span>{t("Page")}</span>
              </Card.Header>

              <Card.Body>
                {detailsActionTemplate}
                <div className="text-end">
                  <Button variant="primary" onClick={onSubmitFunction}>
                    Save Changes
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default PageEditionPage;
