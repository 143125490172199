import EventRuleSelector from "@main/event-rules/components/EventRuleSelector";
import EventSelector from "@main/events/components/EventSelector";

const filterUIConfig = {
  uiScheme: {
    classNames: "filters-container",
    event_rule_id: {
      "ui:widget": "event-rule-selector",
      "ui:options": {
        label: false, // Oculta el título del campo
      },
    },
    search: {
      "ui:options": {
        label: false, // Oculta el título del campo
        placeholder: "Buscar...",
      },
    },
  },
  widgets: {
    "event-rule-selector": EventRuleSelector,
  },
};
const getFilterUIConfig: any = (type: string) => {
  return filterUIConfig;
};
export default getFilterUIConfig;
