import { useContext } from "react";
import getConfig from "../configs/tableConfig";
import BeelditTableHeaderActions from "@beeldit/core/components/BeelditTableHeaderActions";
import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { t } from "i18next";
import useListAction from "../actions/list/useListAction";
import useDetailsAction from "../actions/details/useDetailsAction";
import { Card } from "react-bootstrap";

function UsersActivityPage(props: { filters?: any }) {
  const { filters } = props;

  const { user } = useContext(AuthContext);

  const {
    indexFunction,
    actionTemplate: listActionTemplate,
    setTableConfig,
  } = useListAction(user, filters);

  const { getFunction, actionTemplate: detailsActionTemplate } =
      useDetailsAction(indexFunction);


  /**<newActionsHooks**/

  /**
   * Table configuration definition
   */
  let tableConfig = getConfig({
    indexFunction,
    getFunction
    /**newActionsTableConfig**/
  });
  setTableConfig(tableConfig);

  return (
    <Card>
      <Card.Header className="d-flex align-items-center justify-content-between ps-3 bg-white">
        <span>{t("users_activity")}</span>
        <div>
          <BeelditTableHeaderActions
            actions={tableConfig.actions}
          ></BeelditTableHeaderActions>
        </div>
      </Card.Header>

      <Card.Body>
        {listActionTemplate}
        {detailsActionTemplate}
        {/* newTemplateActions */}
      </Card.Body>
    </Card>
  );
}

export default UsersActivityPage;
