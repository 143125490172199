import { createRef, useContext } from "react";
import JSONSchemaForm from "@rjsf/core";
import useGet from "./useGet";
import useUpdate from "./useUpdate";
import { DetailsActionContext } from "./DetailsActionContext";
import { useNavigate } from "react-router-dom";
import HeaderForm from "@bloonde/cms/cms-headers/components/HeaderForm";
import { showSuccessMessage } from "@beeldit/core/helpers/messagesHelper";

function useDetailsAction(): any {

  const navigate = useNavigate();  

  const detailsFunction = (id: any) => {
      navigate(`/panel/header`);
  };

  const get = useGet();
  const getFunction = () => get();

  /**
   * Form store success function definition
   */
  const onSuccessUpdate = () => {
    showSuccessMessage("successfull_action");    
    getFunction(); // We pass the function to refresh the table on success
  };

  const update = useUpdate();
  const updateFunction = () => update(onSuccessUpdate);
  
  /** Edition element definition */
  const { element } = useContext(DetailsActionContext);

  /**
   * Form Reference definition
   */
  const formRef = createRef<JSONSchemaForm>();  

  const onSubmitFunction = () => formRef.current?.formElement.current.requestSubmit() //We pass the function for the submit of the modal

  /**
   * Form configuration definition
   */
  const formConfig = {
    formRef: formRef, // We pass the ref to the form
    onSubmit: () => {
      updateFunction();
    },
    element: element, // We pass the element to the form in order to render it in the modal form
    context: DetailsActionContext,
    className: "header-form",
  };

  const actionTemplate = (
    <HeaderForm formConfig={formConfig} />
  );

  /** END EDIT FORM ACTION */

  return {
    detailsFunction,
    getFunction,
    onSubmitFunction,
    actionTemplate,
  };
}

export default useDetailsAction;
