import React, { createContext, useState } from "react";

interface FillActionContextType {
  backendFormErrors: any;
  setBackendFormErrors: (backendFormErrors: any) => void;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  element: any;
  setElement: (element: any) => void;
}

export const FillActionContext = createContext<FillActionContextType>({
  backendFormErrors: {},
  setBackendFormErrors: () => {},
  showModal: false,
  setShowModal: () => {},
  element: null,
  setElement: () => {},
});

interface FillActionProviderProps {
  children: React.ReactNode;
}

export const FillActionProvider: React.FC<FillActionProviderProps> = ({
  children,
}) => {
  const [backendFormErrors, setBackendFormErrors] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [element, setElement] = useState<any>(null);

  const value = {
    backendFormErrors,
    setBackendFormErrors,
    showModal,
    setShowModal,
    element,
    setElement,
  };
  return (
    <FillActionContext.Provider value={value}>
      {children}
    </FillActionContext.Provider>
  );
};
