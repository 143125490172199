import { useContext, useEffect } from "react";
import useIndex from "./useIndex";
import AddQueryParamsToURL from "@beeldit/core/helpers/AddQueryParamsToURL";
import { ListActionContext } from "./ListActionContext";
import useFilters from "@beeldit/core/helpers/useFilters";
import BeelditTableFilters from "@beeldit/core/components/BeelditTableFilters";
import BeelditTablePagination from "@beeldit/core/components/BeelditTablePagination";
import getFilterUIConfig from "../../configs/filterUIConfig";
import getFiltersConfig from "../../configs/filterConfig";
import CouponTable from "../../components/CouponTable";

function useListAction(coupon: any, filters: any = {}): any {
  const addQueryParamsToURL: any = AddQueryParamsToURL();

  /** Crud functions */
  const index = useIndex();
  const indexFunction = () => index();

  /** Filter loading */
  const { filtersLoaded, setFiltersLoaded } = useContext(ListActionContext);
  const { tableFilters, setTableFilters } = useContext(ListActionContext);
  const { tableConfig, setTableConfig } = useContext(ListActionContext);
  useFilters({ ...{}, ...filters }, ListActionContext);

  /**Order by config */
  const { orderBy, setOrderBy } = useContext(ListActionContext);

  const onOrderByChange = (orderBy: string) => {
    setOrderBy(orderBy);
  };

  /**
   * Pagination config
   */
  const { paginationConfig, setPaginationConfig } =
    useContext(ListActionContext);

  const onPaginationChange = (page: number) => {
    setPaginationConfig({ ...paginationConfig, page: page });
  };

  /** Index table funcion definition */
  const { tableData } = useContext(ListActionContext);

  useEffect(() => {
    if (filtersLoaded) {
      index();
    }
  }, [tableFilters, paginationConfig, filtersLoaded, orderBy]);

  /**<newActionsFunctions>**/

  /** Table Filters configuration */
  const filterUIConfig = getFilterUIConfig(coupon);
  const filterConfig = {
    schema: getFiltersConfig(coupon),
    uiSchema: filterUIConfig.uiScheme,
    widgets: filterUIConfig.widgets,
    element: tableFilters,
    onChange: (form: any) => {
      setTableFilters(form.formData);
      addQueryParamsToURL(form.formData);
    },
  };

  let actionTemplate =
    filtersLoaded && tableConfig ? (
      <>
        <BeelditTableFilters formConfig={filterConfig} />
        <CouponTable
          tableData={tableData}
          tableConfig={tableConfig}
          onOrderByChange={onOrderByChange}
        />
        <BeelditTablePagination
          paginationConfig={paginationConfig}
          totalElements={tableData.totalElements}
          onPaginationChange={onPaginationChange}
        ></BeelditTablePagination>
      </>
    ) : (
      <>
        Filters: {filtersLoaded.toString()} table config: {!tableConfig}
      </>
    );
  /** END EDIT TABLE ACTION */

  return {
    indexFunction,
    actionTemplate,
    setTableConfig,
  };
}

export default useListAction;
