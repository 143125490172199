import React, { createContext, useState } from "react";

interface BillingGraphContextType {
  graphData: any[];
  setGraphData: (graphData: any[]) => void;
}

export const BillingGraphContext = createContext<BillingGraphContextType>({
  graphData: [],
  setGraphData: () => {},
});

interface BillingGraphProviderProps {
  children: React.ReactNode;
}

export const BillingGraphProvider: React.FC<BillingGraphProviderProps> = ({
  children,
}) => {
  const [graphData, setGraphData] = useState<any[]>();

  const value = {
    graphData,
    setGraphData,
  };
  return (
    <BillingGraphContext.Provider value={value}>
      {children}
    </BillingGraphContext.Provider>
  );
};
