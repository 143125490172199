import { useContext } from "react";
import Noty from "noty";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import { UnlockEventSessionsContext } from "./UnlockEventSessionsContext";
import execute from "@beeldit/core/services/execute";

function useExecuteGenerateEventSessions() {
  const errorHandler = useErrorHandler();
  const { modelId, element, setBackendFormErrors } = useContext(UnlockEventSessionsContext);  
  return (onSuccess: any) => {
    execute(`event_sessions/unlock/${modelId}`, element)
      .then((response: any) => {
        onSuccess(response.data);
      })
      .catch((error: any) => {
        if (error.response.status === 422) {
          new Noty({
            text: error.response.data,
            theme: "relax",
            type: "error",
            layout: "topCenter",
            timeout: 6000,
          }).show();
        }
        errorHandler(error, setBackendFormErrors);
      });
  };
}

export default useExecuteGenerateEventSessions;
