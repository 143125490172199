import { useContext } from "react";
import CrudModuleFactoryModal from "@beeldit/core/components/CrudModuleFactoryModal";
import useGet from "./useGet";
import { DetailsActionContext } from "./DetailsActionContext";

function useDetailsAction(index: any): any {
  const get = useGet();
  const getFunction = (id: number) => get(id);

  /** Creation and edition modal element definition */
  const { element } = useContext(DetailsActionContext);

  /**
   * Creation and edition modal configuration definition
   */
  const modalConfig = {
    title: "user_activity_details",
    context: DetailsActionContext,
    size: "modal-lg",
  };

  const actionTemplate = (
    <CrudModuleFactoryModal modalConfig={modalConfig}>
      {
        element !== null && (
            <div>
              <p>log_name: {element.log_name}</p>
              <p>description: {element.description}</p>
              <p>subject_type: {element.subject_type}</p>
              <p>event: {element.event}</p>
              <p>subject_id: {element.subject_id}</p>
              <p>causer_type: {element.causer_type}</p>
              <p>causer_id: {element.causer_id}</p>
              <textarea disabled={true}>{element.properties ? JSON.stringify(element.properties, null, 2) : null}</textarea>
            </div>
        )
      }
    </CrudModuleFactoryModal>
  );


  return {
    getFunction,
    actionTemplate,
  };
}

export default useDetailsAction;
