const EventColsSchema = [
  [
    {
      name: "name",
      cols: "col-12",
    },
    {
      name: "room_id",
      cols: "col-6",
    },
    {
      name: "event_type_id",
      cols: "col-6",
    },
    {
      name: "sale_deadline",
      cols: "col-6",
    },
    {
      name: "capacity",
      cols: "col-6",
    },
    {
      name: "free",
      cols: "col-6",
    },
    {
      name: "without_registration",
      cols: "col-6",
    },
    {
      name: "external",
      cols: "col-6",
    },
    {
      name: "unique_date",
      cols: "col-6",
    },
    {
      name: "external_url",
      cols: "col-6",
    },    
    {
      name: "date_start",
      cols: "col-6",
    },
  ],
];

export default EventColsSchema;
