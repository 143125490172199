import React, { createContext, useState } from "react";

interface DetailsActionContextType {  
  backendFormErrors: any;
  setBackendFormErrors: (backendFormErrors: any) => void;    
  element: any;
  setElement: (element: any) => void;
  schema: any;
  setSchema: (schema: any) => void;
  schemaUI: any;
  setSchemaUI: (schema: any) => void;
  selectedLang: string;
  setSelectedLang: (selectedLang: string) => void;
}

export const DetailsActionContext = createContext<DetailsActionContextType>({
  backendFormErrors: {},
  setBackendFormErrors: () => {},
  element: null,
  setElement: () => {},
  schema: null,
  setSchema: () => {},
  schemaUI: null,
  setSchemaUI: () => {},
  selectedLang: "",
  setSelectedLang: () => {},
});

interface DetailsActionProviderProps {
  children: React.ReactNode;
}

export const DetailsActionProvider: React.FC<DetailsActionProviderProps> = ({
  children,
}) => {  
  const [backendFormErrors, setBackendFormErrors] = useState(null);
  const [element, setElement] = useState<any>(null);  
  const [schema, setSchema] = useState<any>(null);
  const [schemaUI, setSchemaUI] = useState<any>(null);
  const [selectedLang, setSelectedLang] = useState<string>("");

  const value = {    
    backendFormErrors,
    setBackendFormErrors,
    element,
    setElement,
    schema,
    setSchema,
    schemaUI,
    setSchemaUI,
    selectedLang,
    setSelectedLang,
  };
  return (
    <DetailsActionContext.Provider value={value}>      
      {children}
    </DetailsActionContext.Provider>
  );
};
