import { useContext } from "react";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import get from "@beeldit/core/services/get";
import { DetailsByPageableActionContext } from "./DetailsByPageableActionContext";
import { useTranslation } from "react-i18next";

function useGet() {
  const { setElement, setSelectedLang } = useContext(DetailsByPageableActionContext);
  const errorHandler = useErrorHandler();
  const { t } = useTranslation();

  return (
    id: any,
    pageableType: string,
    onSuccess: any = (data: any) => {
      return data;
    }
  ) => {
    get("pages/byPageable", id, `?pageable_type=${pageableType}&relations=seo,sections,tags&appends=tag_ids`)
      .then((response: any) => {
        let data = response.data;
        data = onSuccess(response.data);
        setElement(data);
        setSelectedLang("es");
      })
      .catch((error: any) => {
        errorHandler(error);
      });
  };
}

export default useGet;
