import { useContext } from "react";
import { CreateActionContext } from "./CreateActionContext";

function useInitialize() {
  const { setElement, setShowModal, setBackendFormErrors } = useContext(CreateActionContext);
  return (formDefaultValues: any) => {
    setBackendFormErrors({});
    setElement(formDefaultValues);
    setShowModal(true);
  };
}

export default useInitialize;
