import { useContext, useEffect, useState } from "react";
import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { useParams } from "react-router-dom";
import useDetailsAction from "../actions/details/useDetailsAction";

import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { DetailsActionContext } from "../actions/details/DetailsActionContext";
import { format } from "path";
import index from "@beeldit/core/services";
import AssignmentStatistics from "../components/AssignmentStatistics";
import useEditAssignationAction from "../actions/edit-assignation/useEditAssignationAction";

function AssignmentPage() {
  const { user } = useContext(AuthContext);

  let { assignmentId } = useParams();

  const { element } = useContext(DetailsActionContext);

  const [candidates, setCandidates] = useState<any[]>();
  const [candidateFilter, setCandidateFilter] = useState<any>();
  const [assignableTypes, setAssignableTypes] = useState<any[]>();
  const [assignableTypeFilter, setAssignableTypeFilter] = useState<any>();

  const [slotMinTime, setSlotMinTime] = useState("08:00:00");
  const [slotMaxTime, setSlotMaxTime] = useState("23:00:00");

  const [events, setEvents] = useState<any[]>();

  if (!assignmentId) {
    assignmentId = "0";
  }

  const {
    getFunction,
    onSubmitFunction,
    actionTemplate: detailsActionTemplate,
  } = useDetailsAction(parseInt(assignmentId));

  const {
    getFunction: editAssignationGetFunction,
    actionTemplate: editAssignationActionTemplate,
  } = useEditAssignationAction(() => {
    getFunction(assignmentId);
  }, parseInt(assignmentId));

  useEffect(() => {
    getFunction(assignmentId);
    getAssignableTypes();
    getCandidates();
  }, [assignmentId]);

  useEffect(() => {
    if (!element || !candidates || !assignableTypes) return;
    formatFullCalendarEvents();
  }, [
    element,
    candidates,
    assignableTypes,
    candidateFilter,
    assignableTypeFilter,
  ]);

  const formatFullCalendarEvents = () => {
    let userCandidate = null;
    if (isGuideRole()) {
      userCandidate = candidates.find(
        (candidate: any) => candidate.id === user.extra_data?.candidate.id
      );
    }
    if (element) {
      let newEvents = [];
      element.assignments.assignments.forEach((assignment: any) => {
        let show = false; // Por defecto no mostramos ningun evento y luego comprobamos si pertenece al candidato del usuario o si el usuario es admin
        // Calculamos el end como la suma de la fecha, la hora y la duración
        const end = new Date(assignment.date + " " + assignment.hour);
        end.setMinutes(end.getMinutes() + assignment.duration);
        let assignments = assignment.assignaments.map((assignment_id: any) => {
          const candidate = candidates.find(
            (candidate: any) => candidate.id === assignment_id
          );
          if ((userCandidate && userCandidate.id === candidate.id) || user.role === "admin") {
            show = true;
          }
          return candidate.name;
        });
        let assignableType = assignableTypes.find(
          (assignableType: any) => assignableType.name === assignment.type
        );

        const checkAssignableTypeFilter =
          !assignableTypeFilter || assignableTypeFilter == assignableType.id;
        const checkCandidateFilter =
          !candidateFilter || assignment.assignaments.includes(candidateFilter);

        if (!checkAssignableTypeFilter || !checkCandidateFilter) return;

        const event = {
          title: assignment.name + " - " + assignments.join(", "),
          start: assignment.date + " " + assignment.hour,
          end: end,
          extendedProps: {
            id: assignmentId,
            assignment_id: assignment.assignation_id,
          },
          backgroundColor: assignableType.color,
        };
        if (show) {
          newEvents.push(event);
        }
      });
      setEvents(newEvents);
    }
  };

  const getAssignableTypes = () => {
    index("assignable_types").then((response: any) => {
      setAssignableTypes(response.data.elements);
    });
  };
  const getCandidates = () => {
    index("candidates").then((response: any) => {
      setCandidates(response.data.elements);
    });
  };

  const candidateSelector = () => {
    return (
      <div>
        <label htmlFor="candidate_id">Candidato</label>
        <select
          name="candidate_id"
          id="candidate_id"
          className="form-control"
          value={element.candidate_id}
          onChange={(e) => {
            setCandidateFilter(parseInt(e.target.value));
          }}
        >
          <option value={0}>Selecciona un candidato</option>
          {candidates.map((candidate) => (
            <option key={candidate.id} value={candidate.id}>
              {candidate.name}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const assignableTypeSelector = () => {
    return (
      <div>
        <label htmlFor="assignable_type_id">Tipo de asignable</label>
        <select
          name="assignable_type_id"
          id="assignable_type_id"
          className="form-control"
          value={element.assignable_type_id}
          onChange={(e) => {
            setAssignableTypeFilter(parseInt(e.target.value));
          }}
        >
          <option value={0}>Selecciona un tipo de asignable</option>
          {assignableTypes.map((assignableType) => (
            <option key={assignableType.id} value={assignableType.id}>
              {assignableType.name}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const isAdminRole = () => {
    return user.role == "admin";
  };

  const isGuideRole = () => {
    return user.role == "guide";
  };

  return (
    <>
      {events ? (
        <>
          {isAdminRole() && candidateSelector()}
          {assignableTypeSelector()}
          <FullCalendar
            plugins={[timeGridPlugin]}
            initialView="timeGridWeek"
            weekends={true}
            events={events}
            slotMinTime={slotMinTime}
            slotMaxTime={slotMaxTime}
            eventClick={(info) => {
              editAssignationGetFunction(
                info.event.extendedProps.id,
                info.event.extendedProps.assignment_id
              );
            }}
          />
          {isAdminRole() && editAssignationActionTemplate}
          {isAdminRole() && (
            <AssignmentStatistics
              candidates={element.assignments.candidates}
              daysOff={element.days_off}
              assignments={element.assignments.assignments}
            />
          )}
        </>
      ) : (
        "loading ... "
      )}
    </>
  );
}

export default AssignmentPage;
