import { ObjectFieldTemplateProps } from "@rjsf/utils";
import useUpdateSectionAction from "../../actions/updateSection/useUpdateSectionAction";

function MainImgTemplate(props: ObjectFieldTemplateProps, func: any) {
  const { actionTemplate } = useUpdateSectionAction();
  return (
    <>
    
    <div className="cms-section">
        <h3>{props.title} {actionTemplate}</h3>
        <div className="section row">
          <div className="col-12">
            <div className="property-wrapper">
              {func("img", props)?.content}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainImgTemplate;
