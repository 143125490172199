import { useContext } from "react";
import { GenerateAssignmentsContext } from "./GenerateAssignmentsContext";
import { GenerateAssignmentsModel } from "./GenerateAssignmentsModel";

function useInitializeGenerateAssignments() {
  const { setElement, setShowModal, setModelId } = useContext(
    GenerateAssignmentsContext
  );
  return (id: number) => {
    setModelId(id);
    setElement(new GenerateAssignmentsModel());
    setShowModal(true);
  };
}

export default useInitializeGenerateAssignments;
