import { t } from "i18next";

const filterConfig = {
  type: 'object',
  properties: {
        // customer_id: { 
        //     type: 'integer', 
        // },
        // report_id: { 
        //     type: 'integer', 
        // },
        start_date: {
          type: "string",
          format: "date",          
          default: new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0],
        },
        end_date: {
          type: "string",
          format: "date",
          default: new Date().toISOString().split('T')[0],
        },
        granularity: {
          title: t('granularity'),
          type: 'string',
          default: 'day',
          enum: ['hour', 'day', 'month', 'year'],
          enumNames: [t('hour'), t('day'), t('month'), t('year')],
        },
        event_id: {
          title: t('event'),
          type: 'integer',
        }
    }
}
const getFiltersConfig: any = (type: string) => {
  return filterConfig;
}
export default getFiltersConfig;