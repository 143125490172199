import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import { t } from 'i18next';

function BookingNotFound() {
  return (
    <Card className='booking-ticket-card' style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>{t('booking_not_found')}</Card.Title>
        <Card.Text>
          <Alert variant='warning'>{t('booking_not_found_text')}</Alert>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default BookingNotFound;