const filterConfig = {
  type: 'object',
  properties: {
      search: {
          type: 'string',
      },
    }
}
const getFiltersConfig: any = (type: string) => {
  return filterConfig;
}
export default getFiltersConfig;
