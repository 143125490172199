import { ArrayFieldTemplateProps } from "@rjsf/utils";
import DefaultArrayTemplate from "./arrays/DefaultArrayTemplate";
import GalleryArrayTemplate from "./arrays/GalleryArrayTemplate";

function PageFormArrayTemplate(props: ArrayFieldTemplateProps) {
  let id = props.idSchema.$id;
  let type = props.uiSchema ? props.uiSchema["ui:type"] : "null";
  let className = id != "root_sections" ? "cms-section" : "";

  if (type == "gallery" || type == "gallery_with_main") {
    return GalleryArrayTemplate(props, className, id);
  } else {
    return DefaultArrayTemplate(props, className, id);
  }
}

export default PageFormArrayTemplate;
