import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import { t } from 'i18next';

function BookingCard(booking: any) {
  return (
    <Card className='booking-ticket-card' style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>
          {booking.booking.invoice_account.fiscal_name + ' ' +
          booking.booking.invoice_account.fiscal_first_surname + ' ' +
          booking.booking.invoice_account.fiscal_last_surname} 
        </Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{booking.booking.invoice_account.email}</Card.Subtitle>
        <Card.Text>
        {t('date')}: {new Date(booking.booking.event_session?.date).toLocaleDateString() + ' '}
        {t('time_start')}: {booking.booking.event_session?.time_start.slice(0, 5)}
        <div className='card-tickets'>
          <strong>{t('tickets')}:</strong>
          <ul>
            {booking.booking.tickets ? booking.booking.tickets.map((ticket: any) => (
              <li key={ticket.id} className='card-ticket'>
                {ticket.event_price_type_name}: {ticket.quantity}
              </li>
            )) : null }
          </ul>
        </div>
          <Alert variant={booking.booking.oldStatus.name !== 'Redeemed' ? 'info' : 'warning'}>{t(booking.booking.oldStatus.name)} {booking.booking.oldStatus.name !== 'Redeemed' && ', ' + t('no_redeemed')}</Alert>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default BookingCard;