import { useEffect, useState } from "react";
import {
  ArrowCircleUp,
  CaretCircleDoubleDown,
  CheckCircle,
} from "@phosphor-icons/react/dist/ssr";
import { Card } from "react-bootstrap";
import { t } from "i18next";

function AssignmentStatistics(props: {
  candidates: any[];
  daysOff: number;
  assignments: any[];
}) {
  const { candidates, daysOff, assignments } = props;

  const [assignationAmountQuality, setAssignationAmountQuality] = useState({
    valid: 0,
    total: 0,
    ratioClass: "text-success",
    ratioIcon: "ok",
  });
  const [weekHolidaysQuality, setWeekweekHolidaysQuality] = useState({
    valid: 0,
    total: 0,
    errors: [],
    ratioClass: "text-success",
    ratioIcon: "ok",
  });
  const [featuresQuality, setFeaturesQuality] = useState({
    valid: 0,
    total: 0,
    errors: [],
    ratioClass: "text-success",
    ratioIcon: "ok",
  });
  const [holidayQuality, setHolidayQuality] = useState({
    valid: 0,
    total: 0,
    errors: [],
    ratioClass: "text-success",
    ratioIcon: "ok",
  });
  const [assignationQuality, setAssignationQuality] = useState({
    valid: 0,
    total: 0,
    errors: [],
    ratioClass: "text-success",
    ratioIcon: "ok",
  });

  useEffect(() => {
    calculateAssignationAmountQuality();
    calculateWeekweekHolidaysQuality();
    calculateFeaturesQuality();
    // TODO - Calcular calidad de preferencias
    calculateHolidayQuality();
    calculateAssignationQuality();
  }, [candidates]);

  const calculateAssignationAmountQuality = () => {
    let newValid = 0;
    let newTotal = 0;
    candidates.map((candidate: any) => {
      Object.keys(candidate.assignations).map((assignationTypeName) => {
        {
          candidate.assignations[assignationTypeName].map(
            (assignation: any, key: any) => {
              const { ratioClass } = calculateRatioParams(
                assignation["assigned"],
                assignation["perCandidate"]
              );
              newValid = newValid + (ratioClass == "text-success" ? 1 : 0);
              newTotal++;
            }
          );
        }
      });
    });
    const { ratio, diff, ratioClass, ratioIcon } = calculateRatioParams(
      newValid,
      newTotal
    );
    setAssignationAmountQuality({
      valid: newValid,
      total: newTotal,
      ratioClass,
      ratioIcon,
    });
  };
  const calculateWeekweekHolidaysQuality = () => {
    let valid = 0;
    let total = candidates.length;
    let errors = [];

    candidates.map((candidate: any) => {
      // Comprobamos que tiene 2 días en cada array de days_off.
      const candidateDaysOff = candidate.days_off;
      const validDaysOff = candidateDaysOff.every(
        (days) => days.length >= daysOff
      );
      const checkDaysOffWithoutAssignament = candidateDaysOff.every((days) => {
        const valid = days.every((day) => {
          const assignments = candidate.assignedHours.filter(
            (assignment) => assignment.day == day
          );
          if (assignments.length > 0) {
            errors.push({ candidate, day });
          }
          return assignments.length == 0;
        });
        return valid;
      });
      valid = valid + (validDaysOff && checkDaysOffWithoutAssignament ? 1 : 0);
    });
    // TODO - También hay que comprobar que estos días de days_off no se encuentran en el listado de horas asignadas del candidato. En caso de que se encuentren no se da por válido
    setWeekweekHolidaysQuality({
      valid,
      total,
      errors,
      ratioClass: valid == total ? "text-success" : "text-danger",
      ratioIcon: valid == total ? "ok" : "down",
    });
  };
  const calculateFeaturesQuality = () => {
    let valid = 0;
    let total = 0;
    let errors = [];
    assignments.map((assignment: any) => {
      total++;
      let validFeatures = true;
      assignment.assignaments.forEach((candidateId: number) => {
        const candidate = candidates.find(
          (candidate) => candidate.id === candidateId
        );
        if (candidate) {
          // Comprobar que todas las features de assignment están en las features del candidato
          const validFeaturesCandidate = assignment.features.every(
            (feature: any) => candidate.features.includes(feature)
          );
          if (!validFeaturesCandidate) {
            validFeatures = false;
          }
        }
      });
      if (validFeatures) {
        valid++;
      } else {
        errors.push(assignment);
      }
    });
    setFeaturesQuality({
      valid,
      total,
      errors,
      ratioClass: valid == total ? "text-success" : "text-danger",
      ratioIcon: valid == total ? "ok" : "down",
    });
  };
  // Tenemos que comprobar que para cada candidato, ninguna de las fechas de holidays se encuentran en assignmentHours
  const calculateHolidayQuality = () => {
    let valid = 0;
    let total = 0;
    let errors = [];
    candidates.map((candidate: any) => {
      total = total + candidate.holidays.length;
      candidate.holidays.forEach((holiday: any) => {
        const validHoliday = !candidate.assignedHours.some(
          (assignment) => assignment.date == holiday
        );
        if (validHoliday) {
          valid++;
        } else {
          errors.push({ candidate, holiday });
        }
      });
    });
    setHolidayQuality({
      valid,
      total,
      errors,
      ratioClass: valid == total ? "text-success" : "text-danger",
      ratioIcon: valid == total ? "ok" : "down",
    });
  };

  // Tenemos que ver que para cada assignment, la cantidad de assignment_amount es igual a assignaments.length
  const calculateAssignationQuality = () => {
    let valid = 0;
    let total = 0;
    let errors = [];
    assignments.map((assignment: any) => {
      total++;
      const validAssignation =
        assignment.assignaments.length >= assignment.assignment_amount;
      if (validAssignation) {
        valid++;
      } else {
        errors.push(assignment);
      }
    });
    setAssignationQuality({
      valid,
      total,
      errors,
      ratioClass: valid == total ? "text-success" : "text-danger",
      ratioIcon: valid == total ? "ok" : "down",
    });
  };

  const calculateRatioParams = (amount, total) => {
    const minRatio = 0.9;
    const maxRatio = 1.1;
    const ratio = amount / total;
    const diff = total - amount;
    const ratioClass: any =
      ratio < minRatio && diff > Math.abs(1)
        ? "text-danger"
        : ratio > maxRatio && diff > Math.abs(1)
        ? "text-warning"
        : "text-success";
    const ratioIcon =
      ratio < minRatio && diff > Math.abs(1)
        ? "down"
        : ratio > maxRatio && diff > Math.abs(1)
        ? "up"
        : "ok";
    return { ratio, diff, ratioClass, ratioIcon };
  };

  const getRatioIcon = (ratioClass, ratioIcon, size = 20) => {
    return ratioIcon == "down" ? (
      <CaretCircleDoubleDown size={size} className={ratioClass} />
    ) : ratioIcon == "up" ? (
      <ArrowCircleUp size={size} className={ratioClass} />
    ) : (
      <CheckCircle size={size} className={ratioClass} />
    );
  };

  return (
    <>
      <div>
        <div className="h2">
          Cantidad de asignaciónes {assignationAmountQuality.valid} /{" "}
          {assignationAmountQuality.total}{" "}
          {getRatioIcon(
            assignationAmountQuality.ratioClass,
            assignationAmountQuality.ratioIcon,
            30
          )}
        </div>
        <div className="row">
          {candidates.map((candidate: any) => {
            return (
              <div className="col-4">
                <Card className="p-4 mb-2">
                  <div className="fw-bold mb-3 text-decoration-underline">
                    Candidato: {candidate.name}
                  </div>
                  <div className="mb-3">
                    <div>
                      Total asignaciones principales:{" "}
                      {candidate.totalAssignations[0]}
                    </div>
                    <div>
                      Total asignaciones secundarias:{" "}
                      {candidate.totalAssignations[1]}
                    </div>
                  </div>
                  <div>
                    {Object.keys(candidate.assignations).map(
                      (assignationTypeName) => {
                        return (
                          <div className="row mb-3">
                            <div className="fw-bold">{assignationTypeName}</div>
                            {candidate.assignations[assignationTypeName].map(
                              (assignation: any, key: any) => {
                                const { ratio, diff, ratioClass, ratioIcon } =
                                  calculateRatioParams(
                                    assignation["assigned"],
                                    assignation["perCandidate"]
                                  );
                                return (
                                  <div className="col-6">
                                    <div className="row">
                                      <div className="col-10 d-inline">
                                        {key == 0
                                          ? "Prinicipales"
                                          : "Secundarias"}
                                        : {assignation["assigned"]} /{" "}
                                        {assignation["perCandidate"].toFixed(2)}
                                      </div>
                                      <div className="col-2 d-inline">
                                        {getRatioIcon(ratioClass, ratioIcon)}
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>
                  <br />
                </Card>
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <div className="h2">
          Días de descanso {weekHolidaysQuality.valid} /{" "}
          {weekHolidaysQuality.total}{" "}
          {getRatioIcon(
            weekHolidaysQuality.ratioClass,
            weekHolidaysQuality.ratioIcon,
            30
          )}
        </div>
        <div className="row">
          {candidates.map((candidate: any) => {
            return (
              <div className="col-4">
                <Card className="p-4 mb-2">
                  <div className="fw-bold mb-3 text-decoration-underline">
                    Candidato: {candidate.name}
                  </div>
                  <div>
                    {candidate.days_off.map((daysOff: any, key: any) => {
                      return (
                        <div>
                          {t("week")} {key + 1} : {daysOff.join(", ")}
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    {weekHolidaysQuality.errors
                      .filter((error) => error.candidate.id == candidate.id)
                      .map((error: any) => {
                        return (
                          <div>
                            <div className="fw-bold text-danger">
                              Error en el día {error.day} del candidato{" "}
                              {error.candidate.name}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <br />
                </Card>
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <div className="h2">
          Características {featuresQuality.valid} / {featuresQuality.total}{" "}
          {getRatioIcon(
            featuresQuality.ratioClass,
            featuresQuality.ratioIcon,
            30
          )}
        </div>
        <div className="row">
          {featuresQuality.errors.map((assignment: any) => {
            return (
              <div className="col-4">
                <Card className="p-4 mb-2">
                  <div className="fw-bold text-danger">
                    {assignment.name} - {assignment.date} - {assignment.hour}
                  </div>
                </Card>
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <div className="h2">
          Vacaciones {holidayQuality.valid} / {holidayQuality.total}{" "}
          {getRatioIcon(
            holidayQuality.ratioClass,
            holidayQuality.ratioIcon,
            30
          )}
        </div>
        <div className="row">
          {holidayQuality.errors.map((error: any) => {
            return (
              <div className="col-4">
                <Card className="p-4 mb-2">
                  <div className="fw-bold text-danger">
                    Error en el día {error.holiday} del candidato{" "}
                    {error.candidate.name}
                  </div>
                </Card>
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <div className="h2">
          Asignaciones {assignationQuality.valid} / {assignationQuality.total}{" "}
          {getRatioIcon(
            assignationQuality.ratioClass,
            assignationQuality.ratioIcon,
            30
          )}
        </div>
        <div className="row">
          {assignationQuality.errors.map((error: any) => {
            return (
              <div className="col-4">
                <Card className="p-4 mb-2">
                  <div className="fw-bold text-danger">
                    Error en la asignación {error.name} - {error.date} -{" "}
                    {error.hour}
                  </div>
                </Card>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default AssignmentStatistics;
