import React, { useContext, useEffect, useState } from "react";

import { ListActionProvider } from "../actions/list/ListActionContext";
import { RemoveActionProvider } from "../actions/remove/RemoveActionContext";
import { CreateActionProvider } from "../actions/create/CreateActionContext";
import { EditActionProvider } from "../actions/edit/EditActionContext";
import InvoiceAccountsPage from "../pages/InvoiceAccountsPage";

export default function InvoiceAccountRouterWrapper(props: {
  config?: any;
  filters?: any;
  key?: string;
  formDefaultValues?: any;
  formFilterValues?: any;
}) {
  const { config, filters, key, formDefaultValues, formFilterValues } = props;

  return (
    <ListActionProvider key={key}>
      <RemoveActionProvider>
        <CreateActionProvider>
          <EditActionProvider>
            <InvoiceAccountsPage
              formDefaultValues={formDefaultValues}
              config={config}
              filters={filters}
              formFilterValues={formFilterValues}
            />
          </EditActionProvider>
        </CreateActionProvider>
      </RemoveActionProvider>
    </ListActionProvider>
  );
}
