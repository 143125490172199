import CommerceSelector from "@main/commerces/components/CommerceSelector";

const filterUIConfig = {
  uiScheme: {
    classNames: "filters-container",
    commerce_id: {
      "ui:widget": "commerce-selector",
      "ui:options": {
        label: false, // Oculta el título del campo
      },
    },
    search: {
      "ui:options": {
        label: false, // Oculta el título del campo
        placeholder: "Buscar...",
      },
    },
  },
  widgets: {
    "commerce-selector": CommerceSelector,
  },
};
const getFilterUIConfig: any = (type: string) => {
  return filterUIConfig;
};
export default getFilterUIConfig;
