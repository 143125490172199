import { useContext } from "react";
import { LockEventSessionsContext } from "./LockEventSessionsContext";
import { LockEventSessionsModel } from "./LockEventSessionsModel";

function useInitializeLockEventSessions() {
  const {setElement, setShowModal, setModelId} = useContext(LockEventSessionsContext);
  return (id: number) => {
    setModelId(id);
    setElement(new LockEventSessionsModel());
    setShowModal(true);
  }
}

export default useInitializeLockEventSessions;