import { useContext, useEffect, useState } from "react";
import { t } from "i18next";
import { Button, Card } from "react-bootstrap";
import useDetailsAction from "../actions/details/useDetailsAction";
import EntriesPage from "@bloonde/cms/cms-entries/pages/EntriesPage";
import { ListActionProvider as EntriesListActionProvider } from "@bloonde/cms/cms-entries/actions/list/ListActionContext";
import { CreateActionProvider as EntriesCreateActionProvider } from "@bloonde/cms/cms-entries/actions/create/CreateActionContext";
import { RemoveActionProvider as EntriesRemoveActionProvider } from "@bloonde/cms/cms-entries/actions/remove/RemoveActionContext";
import { EditActionProvider as EntriesEditActionProvider } from "@bloonde/cms/cms-entries/actions/edit/EditActionContext";
import { DetailsActionContext } from "../actions/details/DetailsActionContext";

function FooterDashboardPage() {
  const {
    getFunction,
    onSubmitFunction,
    actionTemplate: detailsActionTemplate,
  } = useDetailsAction();

  const { element } = useContext(DetailsActionContext);

  const [filters, setFilters] = useState(null);

  const [formFilterValues, setFormFilterValues] = useState(null);

  const [formDefaultValues, setFormDefaultValues] = useState(null);

  useEffect(() => {
    getFunction();
  }, []);

  useEffect(() => {
    setFilters({
      entriesable_id: element ? element.id : null,
      entriesable_type: "Bloonde\\CMS\\Models\\Footer",
    });
    setFormFilterValues({
      entriesable_id: element ? element.id : null,
      entriesable_type: "Bloonde\\CMS\\Models\\Footer",
    });
    setFormDefaultValues({
      entriesable_id: element ? element.id : null,
      entriesable_type: "Bloonde\\CMS\\Models\\Footer",
    });
  }, [element]);

  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-12">
          <div className="mb-5">
            <Card>
              <Card.Header className="d-flex align-items-center justify-content-between ps-3 bg-white">
                <span>{t("Footer")}</span>
              </Card.Header>

              <Card.Body>
                {detailsActionTemplate}
                <div className="text-end">
                  <Button variant="primary" onClick={onSubmitFunction}>
                    {t("save_changes")}
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="col-xl-6 col-12">
          <div className="mb-5">
            {filters && filters.entriesable_id && (
              <EntriesListActionProvider>
                <EntriesRemoveActionProvider>
                  <EntriesCreateActionProvider>
                    <EntriesEditActionProvider>
                      <EntriesPage
                        filters={filters}
                        formDefaultValues={formDefaultValues}
                        formFilterValues={formFilterValues}
                      />
                    </EntriesEditActionProvider>
                  </EntriesCreateActionProvider>
                </EntriesRemoveActionProvider>
              </EntriesListActionProvider>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default FooterDashboardPage;
