import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/bootstrap-4";
import { useTranslation } from "react-i18next";
import { MouseEventHandler, useContext, useEffect, useState } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import StatusSelector from "@beeldit/statuses-and-actions/components/StatusSelector";
import BeelditTypeSelector from "@beeldit/core/components/BeelditTypeSelector";
import CitiyStringSelector from "@bloonde/location/cities/components/CitiyStringSelector";
import CountryIdMultiSelector from "@bloonde/location/countries/components/CountryIdMultiSelector";
import TagMultiSelector from "@beeldit/crm/crm-tags/components/TagMultiSelector";
import NotificationSelector from "@beeldit/crm/notifications/components/NotificationSelector";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
    context: any;
  };
}

interface Context {
  element: any;
  setElement: (element: any) => MouseEventHandler;
  backendFormErrors: any;
}

function CampaignForm(props: Prop) {
  const { formRef, onSubmit, context } = props.formConfig;
  const { element, setElement, backendFormErrors } =
    useContext<Context>(context);

  const { t } = useTranslation();

  const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});

  const translations = {
    name: t("name"),
    status_id: t("status_id"),
    type: t("type"),
    start_date: t("start_date"),
    end_date: t("end_date"),
    filters: t("segment"),
    cities: t("cities"),
    states: t("states"),
    countries: t("countries"),
    tags: t("tags"),
    event: t("action"),
    delay: t("delay"),
    templates: t("templates"),
    es: t("es"),
    en: t("en"),
    de: t("de"),
  };

  let schema = {
    type: "object",
    // required: ['minutes', 'customer_id', 'task', 'date', 'report_id'],
    properties: {
      name: {
        type: "string",
        title: translations.name,
      },
      status_id: {
        // TODO - Hay que crear un selector de status filtrado por la clase
        type: "number",
        title: translations.status_id,
        default: 11, // draft
      },
      type: {
        type: "number",
        title: translations.type,
        default: 0,
      },
      start_date: {
        type: ["string", "null"],
        title: translations.start_date,
        format: "datetime",
        show: element.type == 0 || element.type == 1, // unique or temporary
      },
      end_date: {
        type: ["string", "null"],
        title: translations.end_date,
        format: "datetime",
        show: element.type == 1, // temporary
      },
      templates: {
        type: "object",
        title: translations.templates,
        properties: {
          es: {
            type: "number",
            title: translations.es,
          },
          en: {
            type: "number",
            title: translations.en,
          },
        },
      },
      filters: {
        type: "object",
        title: translations.filters,
        properties: {
          cities: {
            title: translations.cities,
            type: "array",
            items: {
              type: "string",
            },
          },
          // states: {
          //   type: "array",
          //   items: {
          //     type: "number",
          //   },
          // },
          countries: {
            title: translations.countries,
            type: "array",
            items: {
              type: "number",
            },
          },
          tag_ids: {
            title: translations.tags,
            type: "array",
            items: {
              type: ["number", "string"],
            },
          },
          event: {
            type: "object",
            title: translations.event,
            properties: {
              name: {
                // Selector enum
                type: ["string", "null"],
                title: translations.name,
                default: null,
                oneOf: [
                  {
                    const: null,
                    title: "Ninguno",
                  },
                  {
                    const: "after_visit",
                    title: "Despues de una visita",
                  },
                ],
              },
              delay: {
                type: "number",
                title: translations.delay,
                default: 0,
                minimum: 0,
              },
            },
          },
        },
      },
    },
  };
  const uiSchema: UiSchema = {
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
    status_id: {
      "ui:widget": "status-selector",
      statusable_type: "Beeldit\\Crm\\Models\\Campaign",
    },
    type: {
      "ui:widget": "type-selector",
      "ui:options": {
        configPath: "types.campaigns.type",
      },
    },
    filters: {
      cities: {
        "ui:widget": "city-selector",
      },
      countries: {
        "ui:widget": "country-selector",
      },
      tag_ids: {
        "ui:widget": "tag-multi-select",
      },
    },
    templates: {
      es: {
        "ui:widget": "notification-select",
        "ui:placeholder": t("select_template"),
      },
      en: {
        "ui:widget": "notification-select",
        "ui:placeholder": t("select_template"),
      },
    },
    // address: {
    //   country_id: {
    //     "ui:widget": "country-selector",
    //   },
    //   state_id: {
    //     "ui:widget": "state-selector",
    //   },
    // },
    // tag_ids: {
    //   "ui:widget": "tag-multi-select",
    // },
  };
  const customWidgets = {
    ...widgets,
    ...{
      "status-selector": StatusSelector,
      "type-selector": BeelditTypeSelector,
      "city-selector": CitiyStringSelector,
      "country-selector": CountryIdMultiSelector,
      "tag-multi-select": TagMultiSelector,
      "notification-select": NotificationSelector,
      // "country-selector": CountryIdSelector,
      // "state-selector": StateSelector,
      // "tag-multi-select": TagMultiSelector,
    },
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  const processSchemaFunction = useProcessSchema();

  useEffect(() => {
    // if (
    //   element.address &&
    //   element.address.country_id &&
    //   element.address.country_id == 238
    // ) {
    //   schema.properties.address.properties.state_id = {
    //     type: "number",
    //     title: translations.state_id,
    //     show: element.country_id == 238,
    //   };
    //   delete schema.properties.address.properties.state;
    // } else if (
    //   element.address &&
    //   element.address.country_id &&
    //   element.address.country_id != 238
    // ) {
    //   schema.properties.address.properties.state = {
    //     type: "string",
    //     title: translations.state_id,
    //     show: element.country_id != 238,
    //   };
    //   delete schema.properties.address.properties.state_id;
    // }
    setFinalSchema(processSchemaFunction(schema, element));
  }, [element]);

  return (
    <JSONSchemaForm
      noHtml5Validate
      showErrorList={false}
      ref={formRef}
      schema={finalSchema}
      uiSchema={uiSchema}
      widgets={customWidgets}
      formData={element}
      validator={validator}
      onChange={handleChange}
      onSubmit={onSubmit}
      onError={log("errors")}
      extraErrors={backendFormErrors}
    />
  );
}

export default CampaignForm;
