// src/authInterceptor.js

const AuthInterceptor = (getToken: any) => {
  return (config: any) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  };
};

export default AuthInterceptor;