import ActionFilter from "@beeldit/user-and-access/activity/components/ActionFilter";
import SubjectTypeFilter from "@beeldit/user-and-access/activity/components/SubjectTypeFilter";
import RoleSelector from "@beeldit/user-and-access/users/components/RoleSelector";

const filterUIConfig = {
    uiScheme: {
        classNames: "filters-container",
        description: {
            "ui:widget": "action-filter",
            "ui:options": {
                label: false, // Oculta el título del campo
            }
        },
        subject_type: {
            "ui:widget": "subject-type-filter",
            "ui:options": {
                label: false, // Oculta el título del campo
            }
        },
        role: {
            "ui:widget": "role-filter",
            "ui:options": {
                label: false, // Oculta el título del campo
            }
        },
    },
    widgets: {
        'action-filter': ActionFilter,
        'subject-type-filter': SubjectTypeFilter,
        'role-filter': RoleSelector,
    }
}

const getFilterUIConfig: any = (type: string) => {
  return filterUIConfig;
};
export default getFilterUIConfig;
