import { createRef, useContext } from "react";
import JSONSchemaForm from "@rjsf/core";
import useCheck from "./useCheck";
import { DetailsActionContext } from "./DetailsActionContext";
import QRScannerForm from "@main/qr-scanner/components/QRScannerForm";
import { showSuccessMessage } from "@beeldit/core/helpers/messagesHelper";

function useDetailsAction(id: number): any {

  /**
   * Form store success function definition
   */
  const onSuccessUpdate = (element) => {
    setElement(element);
  };

  const check = useCheck();
  const updateFunction = (element) => check(element, onSuccessUpdate);
  
  /** Edition element definition */
  const { element, setElement } = useContext(DetailsActionContext);

  /**
   * Form Reference definition
   */
  const formRef = createRef<JSONSchemaForm>();  

  const onSubmitFunction = () => formRef.current?.formElement.current.requestSubmit() //We pass the function for the submit of the modal

  /**
   * Form configuration definition
   */
  const formConfig = {
    formRef: formRef, // We pass the ref to the form
    onSubmit: (element) => {
      updateFunction(element);
    },
    element: element, // We pass the element to the form in order to render it in the modal form
    context: DetailsActionContext,
    className: "header-form",
  };

  const actionTemplate = (
    <QRScannerForm formConfig={formConfig} />
  );

  /** END EDIT FORM ACTION */

  return {
    onSubmitFunction,
    actionTemplate,
  };
}

export default useDetailsAction;
