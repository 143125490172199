
const EventPriceTypeColsSchema = [
  [
    {
      name: "name",
      cols: "col-12"
    }
  ],
  [
    {
      name: "min_age",
      cols: "col-6"
    },
    {
      name: "max_age",
      cols: "col-6"
    }
  ],
  [
    {
      name: "student",
      cols: "col-6"
    },
    {
      name: "retired",
      cols: "col-6"
    }
  ],    
  [
    {
      name: "description",
      cols: "col-12"
    }
  ]
]

export default EventPriceTypeColsSchema;
