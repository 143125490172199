import { useContext } from "react";
import getConfig from "../configs/tableConfig";
import BeelditTableHeaderActions from "@beeldit/core/components/BeelditTableHeaderActions";
import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { t } from "i18next";
import useRemoveAction from "../actions/remove/useRemoveAction";
import useCreateAction from "../actions/create/useCreateAction";
import useEditAction from "../actions/edit/useEditAction";
import useListAction from "../actions/list/useListAction";
import { Card } from "react-bootstrap";
import useGeneratePDFAction from "../actions/generate-pdf/GeneratePDFAction";
import useGenerateFacturaeAction from "../actions/generate-facturae/GenerateFacturaeAction";
import useDownloadPDFAction from "../actions/download-pdf/DownloadPDFAction";
import useDownloadXMLAction from "../actions/download-xml/DownloadXMLAction";

function InvoicesPage(props: any) {
  const { filters, formDefaultValues, formWidgets, invoiceConfiguration, showReceiverAccountSelector } =
    props;

  const { user } = useContext(AuthContext);

  const {
    indexFunction,
    actionTemplate: listActionTemplate,
    setTableConfig,
  } = useListAction(user);

  const { initializeFunction, actionTemplate: createActionTemplate } =
    useCreateAction(
      indexFunction,
      formDefaultValues,
      formWidgets,
      invoiceConfiguration,
      showReceiverAccountSelector
    );

  const { getFunction, actionTemplate: editActionTemplate } = useEditAction(
    indexFunction,
    formDefaultValues,
    formWidgets,
    invoiceConfiguration,
    showReceiverAccountSelector
  );

  const { prepareRemoveFunction, actionTemplate: removeActionTemplate } =
    useRemoveAction(indexFunction);

  const {
    initializeFunction: initializeGeneratePDFFunction,
    actionTemplate: generateGeneratePDFActionTemplate,
  } = useGeneratePDFAction(indexFunction);

  const { downloadPDFFunction: downloadPDFFunction } = useDownloadPDFAction();

  const { downloadXMLFunction: downloadXMLFunction } = useDownloadXMLAction();

  const {
    initializeFunction: initializeGenerateFacturaeFunction,
    actionTemplate: generateGenerateFacturaeActionTemplate,
  } = useGenerateFacturaeAction(indexFunction);

  /**<newActionsHooks**/

  /**
   * Table configuration definition
   */
  let tableConfig = getConfig({
    initializeFunction, // We pass the function to initialize a new element (used in the create button)
    indexFunction, // We pass the function to index the table
    getFunction, // We pass the function to get an element (used in the edit button)
    prepareRemoveFunction,
    initializeGeneratePDFFunction,
    initializeGenerateFacturaeFunction,
    downloadPDFFunction,
    downloadXMLFunction,
    /**newActionsTableConfig**/
  });
  setTableConfig(tableConfig);

  return (
    <Card>
      <Card.Header className="d-flex align-items-center justify-content-between ps-3 bg-white">
        <span>{t("Invoices")}</span>
        <div>
          <BeelditTableHeaderActions
            actions={tableConfig.actions}
          ></BeelditTableHeaderActions>
        </div>
      </Card.Header>

      <Card.Body>
        {listActionTemplate}
        {createActionTemplate}
        {editActionTemplate}
        {removeActionTemplate}
        {generateGeneratePDFActionTemplate}
        {generateGenerateFacturaeActionTemplate}
        {/* newTemplateActions */}
      </Card.Body>
    </Card>
  );
}

export default InvoicesPage;
