import React, { createContext, useState } from "react";

interface UnlockEventSessionsContextType {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  element: any;
  setElement: (element: any) => void;  
  backendFormErrors: any;
  setBackendFormErrors: (backendFormErrors: any) => void;
  modelId: any;
  setModelId: (modelId: any) => void;
}

export const UnlockEventSessionsContext = createContext<UnlockEventSessionsContextType>({  
  showModal: false,
  setShowModal: () => {},
  element: null,
  setElement: () => {},
  backendFormErrors: {},
  setBackendFormErrors: () => {},
  modelId: null,
  setModelId: () => {},
});

interface UnlockEventSessionsProviderProps {
  children: React.ReactNode;
}

export const UnlockEventSessionsProvider: React.FC<UnlockEventSessionsProviderProps> = ({
  children,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [element, setElement] = useState<any>(null);
  const [backendFormErrors, setBackendFormErrors] = useState<any>({});
  const [modelId, setModelId] = useState<any>(null);


  const value = {
    showModal,
    setShowModal,
    element,
    setElement,
    backendFormErrors,
    setBackendFormErrors,
    modelId,
    setModelId,
  };
  return (
    <UnlockEventSessionsContext.Provider value={value}>
      {children}
    </UnlockEventSessionsContext.Provider>
  );
};
