import React from "react";
import { Editor } from "@tinymce/tinymce-react";

interface BeelditWYSIWYGInputProps {
  value: string;
  onChange: (content: string) => void;
}

const BeelditWYSIWYGInput: React.FC<BeelditWYSIWYGInputProps> = ({
  value,
  onChange,
}) => {
  const handleEditorChange = (content: string) => {
    onChange(content);
  };

  return (
    <Editor
      value={value}
      init={{
        height: 500,
        menubar: false,
        plugins: [
          "advlist",
          "autolink",
          "lists",
          "link",
          "image",
          "charmap",          
          "preview",
          "anchor",
          "searchreplace",
          "visualblocks",
          "code",
          "fullscreen",
          "insertdatetime",
          "media",
          "table",
          "code",
          "help",
          "wordcount",
        ],
        toolbar:
          "undo redo | blocks fontfamily fontsize | code | formatselect" +
          "bold italic backcolor | alignleft aligncenter " +
          "alignright alignjustify | bullist numlist outdent indent | " +
          "removeformat | fontsizeselect fontselect | insertdatetime | media | help",
      }}
      onEditorChange={handleEditorChange}
      apiKey={
        process.env.REACT_APP_TINYMCE_API_KEY
          ? process.env.REACT_APP_TINYMCE_API_KEY
          : "6nvtbhmshlppl7uebe3axkg8pg7uok8fdt1wfk3vituq37ng"
      }
    />
  );
};

export default BeelditWYSIWYGInput;
