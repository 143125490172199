import { useContext, useEffect } from "react";
import BeelditTable from "@beeldit/core/components/BeelditTable";
import { FinalTableConfig } from "@beeldit/core/models/FinalTableConfig.interface";
import { ListActionContext } from "../actions/list/ListActionContext";

interface Props {
  tableConfig: FinalTableConfig;
  tableData: any;
  onOrderByChange: any;
}

function NotificationTable(props: Props) {
  const { tableData, tableConfig, onOrderByChange } = props;
  const { orderBy } = useContext(ListActionContext);

  return tableData ? (
    <BeelditTable
      config={tableConfig}
      data={tableData}
      orderBy={orderBy}
      onOrderByChange={onOrderByChange}
    ></BeelditTable>
  ) : (
    <div>Loading...</div>
  );
}

export default NotificationTable;
