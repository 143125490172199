import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { RowAction } from "@beeldit/core/enums/RowAction.enum";
import { TableAction } from "@beeldit/core/enums/TableAction.enum";
import { FinalTableConfig } from "@beeldit/core/models/FinalTableConfig.interface";
import {
  Eye,
  Trash,
  Plus,
  ArrowsClockwise,
  Pencil,
} from "@phosphor-icons/react";

const tableConfig: FinalTableConfig = {
  index: null,
  actions: [
    {
      name: TableAction.CREATE,
      label: "create",
      showLabel: true,
      allowed: true,
      show: true,
      action: null,
      icon: <Plus size={20} />,
    },
  ],
  rowActions: [
    {
      name: RowAction.EDIT,
      label: "edit",
      icon: <Pencil size={20} />,
      allowed: true,
      show: true,
      action: (element: any) => {
        console.log("edit", element);
      },
    },
    {
      name: RowAction.DELETE,
      label: "delete",
      icon: <Trash size={20} />,
      allowed: true,
      show: true,
      action: (element: any) => {
        console.log("delete", element);
      },
    },
  ],
  rowConfiguration: {
    class: (element: any) => {
      return null;
    },
  },
  columns: [
    {
      name: "id",
      label: "#",
      key: "id",
      type: "number",
      show: true,
      allowOrder: true,
    },
    {
      name: "name",
      label: "name",
      key: "name",
      type: "string",
      show: true,
      allowOrder: true,
    }
  ],
  pagination: {
    enabled: true,
    back: true,
  },
};
const getConfig: any = (functions: any) => {
  const {
    getFunction,
    indexFunction,
    initializeFunction,
    prepareRemoveFunction,
  } = functions;
  tableConfig.actions[0].action = () => initializeFunction();
  tableConfig.index = () => indexFunction();
  tableConfig.rowActions[0].action = (id: any) => getFunction(id);
  tableConfig.rowActions[1].action = (id: any) => prepareRemoveFunction(id);
  return tableConfig;
};
export default getConfig;
