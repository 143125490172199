import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/bootstrap-4";
import { useTranslation } from "react-i18next";
import { MouseEventHandler, useContext, useEffect, useState } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import AddressSelector from "@bloonde/location/addresses/components/AddressSelector";
import BeelditSwitchInput from "@beeldit/core/components/BeelditSwitchInput";
import AssignableTypeSelector from "@beeldit/resource-management/assignable-types/components/AssignableTypeSelector";
import FeatureMultiSelector from "@beeldit/resource-management/features/components/FeatureMultiSelector";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
    context: any;
  };
}

interface Context {
  element: any;
  setElement: (element: any) => MouseEventHandler;
  backendFormErrors: any;
}

function AssignableForm(props: Prop) {
  const { formRef, onSubmit, context } = props.formConfig;
  const { element, setElement, backendFormErrors } =
    useContext<Context>(context);

  const { t } = useTranslation();

  const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});

  const translations = {
    name: t("name"),
    features: t("features"),
    assignable_type_id: t("assignable_type_id"),
    schedule: t("schedule"),
    previous_time: t("previous_time"),
    assignment_amount: t("assignment_amount"),
    duration: t("duration"),
  };

  let schema: RJSFSchema = {
    type: "object",
    // required: ['minutes', 'customer_id', 'task', 'date', 'report_id'],
    properties: {
      name: {
        type: "string",
        title: translations.name,
      },
      assignable_type_id: {
        type: "number",
        title: translations.assignable_type_id,
      },
      duration: {
        type: "number",
        title: translations.duration,
        default: 0,
        minimum: 0,
      },
      previous_time: {
        type: "number",
        title: translations.previous_time,
        default: 0,
        minimum: 0,
      },
      assignment_amount: {
        type: "number",
        title: translations.assignment_amount,
        default: 1,
        minimum: 1,
      },
      feature_ids: {
        type: "array",
        title: translations.features,
        items: {
          type: "number",
        },
      },
      schedule: {
        type: "array",
        title: translations.schedule,
        items: {
          type: "object",
          properties: {
            day: {
              type: "string",
              title: t("day"),
              enum: [
                "all",
                "monday",
                "tuesday",
                "wednesday",
                "thursday",
                "friday",
                "saturday",
                "sunday",
              ],
              default: "all",
            },
            start_time: {
              type: "string",
              title: t("start_time"),
              format: "time",
            },
          },
        },
      },
    },
  };
  const uiSchema: UiSchema = {
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
    assignable_type_id: {
      "ui:widget": "assignable-type-selector",
      "ui:placeholder": t("select_a_type"),
    },
    feature_ids: {
      "ui:widget": "feature-selector",
      // "ui:placeholder": t("select_features"),
    },
  };
  const customWidgets = {
    ...widgets,
    ...{
      "assignable-type-selector": AssignableTypeSelector,
      "feature-selector": FeatureMultiSelector,
    },
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  const processSchemaFunction = useProcessSchema();

  useEffect(() => {
    setFinalSchema(processSchemaFunction(schema, element));
  }, [element]);

  return (
    <JSONSchemaForm
      noHtml5Validate
      showErrorList={false}
      ref={formRef}
      schema={schema}
      uiSchema={uiSchema}
      widgets={customWidgets}
      formData={element}
      validator={validator}
      onChange={handleChange}
      onSubmit={onSubmit}
      onError={log("errors")}
      extraErrors={backendFormErrors}
    />
  );
}

export default AssignableForm;
