import { Pagination } from "react-bootstrap";
import { Fragment, useEffect, useState } from "react";
import { PaginationConfig } from "../models/PaginationConfig.interface";

interface Prop {
  paginationConfig: PaginationConfig;
  totalElements: number | undefined;
  onPaginationChange: any;
}

function BeelditTablePagination(props: Prop) {
  const { paginationConfig, totalElements, onPaginationChange } = props;
  const [pages, setPages] = useState<any[]>([]);

  useEffect(() => {
    const pages = calculateVisiblePages(
        paginationConfig,
        totalElements ? totalElements : 0
    );
    setPages(pages);
  }, [paginationConfig, totalElements]);

  return (
      <div className="float-end">
        <Pagination>
          <Pagination.First onClick={() => onPaginationChange(1)} />
          <Pagination.Prev
              onClick={() =>
                  onPaginationChange(calculatePreviousPage(paginationConfig))
              }
          />
          {pages.map((page: any) => (
              <Fragment key={page.key}>
                {page.ellipsis ? (
                    <Pagination.Ellipsis />
                ) : (
                    <Pagination.Item
                        active={page.active}
                        onClick={() => onPaginationChange(page.number)}
                    >
                      {page.number}
                    </Pagination.Item>
                )}
              </Fragment>
          ))}
          <Pagination.Next
              onClick={() =>
                  onPaginationChange(
                      calculateNextPage(
                          paginationConfig,
                          totalElements ? totalElements : 0
                      )
                  )
              }
          />
          <Pagination.Last
              onClick={() =>
                  onPaginationChange(
                      calculateLastPage(
                          paginationConfig,
                          totalElements ? totalElements : 0
                      )
                  )
              }
          />
        </Pagination>
        <br />
      </div>
  );
}

function calculateVisiblePages(
    paginationConfig: PaginationConfig,
    totalElements: number
) {
  const active = paginationConfig.page;
  const lastPage = calculateLastPage(paginationConfig, totalElements);
  const delta = 2; // Número de páginas a mostrar por delante y por detrás
  let startPage = Math.max(active - delta, 1);
  let endPage = Math.min(active + delta, lastPage);

  const pages: any[] = [];

  // Primeros elementos
  if (startPage > 1) {
    pages.push({ key: 1, number: 1, active: false });
    if (startPage > 2) {
      pages.push({ key: "start-ellipsis", ellipsis: true });
    }
  }

  // Rango visible
  for (let number = startPage; number <= endPage; number++) {
    pages.push({
      key: number,
      active: number === active,
      number: number,
    });
  }

  // Últimos elementos
  if (endPage < lastPage) {
    if (endPage < lastPage - 1) {
      pages.push({ key: "end-ellipsis", ellipsis: true });
    }
    pages.push({ key: lastPage, number: lastPage, active: false });
  }

  return pages;
}

function calculatePreviousPage(paginationConfig: PaginationConfig) {
  return Math.max(paginationConfig.page - 1, 1);
}

function calculateNextPage(
    paginationConfig: PaginationConfig,
    totalElements: number
) {
  return Math.min(
      paginationConfig.page + 1,
      calculateLastPage(paginationConfig, totalElements)
  );
}

function calculateLastPage(
    paginationConfig: PaginationConfig,
    totalElements: number
) {
  return Math.ceil(totalElements / paginationConfig.elementsPerPage);
}

export default BeelditTablePagination;
