import { t } from "i18next";
import { Button, Card } from "react-bootstrap";
import useDetailsAction from "../actions/details/useDetailsAction";

function QRScannerDashboardPage() {
  const headerId = 1;

  const { onSubmitFunction, actionTemplate: detailsActionTemplate } =
    useDetailsAction(headerId);

  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-12">
          <div className="mb-5">
            <Card>
              <Card.Header className="d-flex align-items-center justify-content-between ps-3 bg-white">
                <span>{t("Header")}</span>
              </Card.Header>

              <Card.Body>
                {detailsActionTemplate}
                <div className="text-end">
                  <Button variant="primary" onClick={onSubmitFunction}>
                    {t("check_qr")}
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </div>

          <div className="mb-5"></div>
        </div>
      </div>
    </>
  );
}

export default QRScannerDashboardPage;
