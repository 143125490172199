import { useContext } from "react";
import { CreateActionContext } from "./CreateActionContext";
import init from "@beeldit/core/services/init";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";

function useInitialize() {
  const { setElement, setShowModal } = useContext(CreateActionContext);
  const errorHandler = useErrorHandler();
  return () => {
    setElement({});
    setShowModal(true);
  }
}

export default useInitialize;
