import { useContext } from "react";

import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import get from "@beeldit/core/services/get";
import { TenantAndCommerceContext } from "@main/contexts/TenantAndCommerceContext";

function useGetConfig() {
  // const errorHandler = useErrorHandler();
  const { setCommerce } = useContext(TenantAndCommerceContext);

  return (
    id: any,
    onSuccess: any = (data: any) => {
      return data;
    }
  ) => {
    get("commerces", id, '?relations=invoiceConfiguration')
      .then((response: any) => {
        let data = response.data;
        data = onSuccess(response.data);
        setCommerce(data);
      })
      .catch((error: any) => {
        // errorHandler(error); // TODO - Lo he quitado porque hay un fallo que hace que redirija al login
      });
  };
}

export default useGetConfig;
