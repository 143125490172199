import React, { useContext, useEffect, useState } from "react";

import { FillActionProvider } from "../actions/fill/FillActionContext";
import FillInvoiceAccountPage from "../pages/FillInvoiceAccountPage";

export default function FillInvoiceAccountRouterWrapper() {  

  return (
    <FillActionProvider>
      <FillInvoiceAccountPage />
    </FillActionProvider>
  );
}
