// import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { RowAction } from "@beeldit/core/enums/RowAction.enum";
import { TableAction } from "@beeldit/core/enums/TableAction.enum";
import { FinalTableConfig } from "@beeldit/core/models/FinalTableConfig.interface";
import { Trash, Plus, ArrowsClockwise, Pencil, ArchiveTray, FilePdf, FileArrowDown, Signature } from "@phosphor-icons/react";

const tableConfig: FinalTableConfig = {
      index: null,
      actions: [
        {
          name: TableAction.CREATE, 
          showLabel: true,
          label: 'create',
          allowed: true,
          show: true,
          action: null,
          icon: <Plus size={20} />,
        },
      ],
      rowActions: [
        {
          name: RowAction.EDIT, 
          label: 'edit',
          icon: <Pencil size={20} />,
          allowed: true,
          show: true,
          action: (element: any) => {
            console.log('edit', element);
          }
        },
        {
          name: 'generate-pdf', 
          label: 'generate-pdf',
          icon: <FilePdf size={20} />,
          allowed: true,
          show: (element: any) => {
            return !element.pdf_generated;
          },
          action: (element: any) => {
            console.log('edit', element);
          }
        },
        {
          name: 'download-pdf', 
          label: 'download-pdf',
          icon: <FileArrowDown size={20} />,
          allowed: true,
          data: 'pdf.0.url',
          show: (element: any) => {
            return element.pdf_generated;
          },
          action: (element: any) => {
            console.log('edit', element);
          }
        },
        {
          name: 'sync', 
          label: 'sync',
          icon: <Signature size={20} />,
          allowed: true,          
          show: (element: any) => {
            return !element.xml_generated;
          },
          action: (element: any) => {
          }
        },
        {
          name: 'download-xml', 
          label: 'download-xml',
          icon: <ArchiveTray size={20} />,
          allowed: true,  
          data: 'xml.0.url',        
          show: (element: any) => {
            return element.xml_generated;
          },
          action: (element: any) => {
          }
        },
        {
          name: RowAction.DELETE, 
          label: 'delete',
          icon: <Trash size={20} />,
          allowed: true,
          show: true,
          action: (element: any) => { 
            console.log('delete', element);
          }              
        }
      ],
      rowConfiguration: {
        class: (element: any) => {
          return null;
        }
      },
      columns: [
        {
          name: 'id',
          label: '#',
          key: 'id',
          type: 'number',
          show: true,
          allowOrder: true,
        },
        {
          name: 'reference',
          label: 'reference',
          key: 'reference',
          type: 'string',
          show: true,
          allowOrder: true,
        },
        {
          name: 'name',
          label: 'name',
          key: 'receiver_account.fiscal_name',
          type: 'string',
          show: true,
          allowOrder: true,
        },
        {
          name: 'surname',
          label: 'surname',
          key: 'receiver_account.fiscal_first_surname',
          type: 'string',
          show: true,
          allowOrder: true,
        },
        {
          name: 'date',
          label: 'date',
          key: 'date',
          type: 'date',
          show: true,
          allowOrder: true,
        },
        {
          name: 'amount_without_vat',
          label: 'amount_without_vat',
          key: 'amount_without_vat',
          type: 'currency',
          currency: 'EUR',
          show: true,
          allowOrder: true,
        },
        {
          name: 'amount_vat',
          label: 'amount_vat',
          key: 'amount_vat',
          type: 'currency',
          currency: 'EUR',
          show: true,
          allowOrder: true,
        },
        {
          name: 'amount_with_vat',
          label: 'amount_with_vat',
          key: 'amount_with_vat',
          type: 'currency',
          currency: 'EUR',
          show: true,
          allowOrder: true,
        },
        {
          name: 'status',
          label: 'status',
          key: 'status_name',
          type: 'string',
          show: true,
          allowOrder: true,
        },
  
      ],
      pagination: {
        enabled: true,
        back: true
    }
}
const getConfig: any = (functions: any) => {
    const {
      getFunction,
      indexFunction,
      initializeFunction,
      prepareRemoveFunction,
      initializeGeneratePDFFunction,
      downloadPDFFunction,
      downloadXMLFunction,
      initializeGenerateFacturaeFunction
    } = functions;
    tableConfig.actions[0].action = () => initializeFunction();
    tableConfig.index = () => indexFunction();
    tableConfig.rowActions[0].action = (id: any) => getFunction(id);
    tableConfig.rowActions[1].action = (id: any) => initializeGeneratePDFFunction(id);
    tableConfig.rowActions[2].action = (url: string) => downloadPDFFunction(url);
    tableConfig.rowActions[3].action = (id: any) => initializeGenerateFacturaeFunction(id);
    tableConfig.rowActions[4].action = (url: string) => downloadXMLFunction(url);
    tableConfig.rowActions[5].action = (id: any) => prepareRemoveFunction(id);
    return tableConfig;
}
export default getConfig;