const BookingTicketsColsSchema = [
  [
    {
      name: "event_price_type_name",
      cols: "col-4",
    },
    {
      name: "quantity",
      cols: "col-4",
    },
    {
      name: "event_rule_price",
      cols: "col-4",
    }
  ],
];

export default BookingTicketsColsSchema;
