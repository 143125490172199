import React, { createContext, useState } from "react";

// TODO Esto tendría que ser como la acción de detail, para que no vaya en un modal
// TODO PENDIENTE, NO SE IMPLEMENTA EN 2 EXTREMOS
interface CreateActionContextType {  
  backendFormErrors: any;
  setBackendFormErrors: (backendFormErrors: any) => void;  
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  element: any;
  setElement: (element: any) => void;  
}

export const CreateActionContext = createContext<CreateActionContextType>({  
  backendFormErrors: {},
  setBackendFormErrors: () => {},
  showModal: false,
  setShowModal: () => {},
  element: null,
  setElement: () => {},  
});

interface CreateActionProviderProps {
  children: React.ReactNode;
}

export const CreateActionProvider: React.FC<CreateActionProviderProps> = ({
  children,
}) => {  
  const [backendFormErrors, setBackendFormErrors] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [element, setElement] = useState<any>(null);  

  const value = {    
    backendFormErrors,
    setBackendFormErrors,
    showModal,
    setShowModal,
    element,
    setElement,    
  };
  return (
    <CreateActionContext.Provider value={value}>
      {children}
    </CreateActionContext.Provider>
  );
};
