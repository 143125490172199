import CommerceSelector from "@main/commerces/components/CommerceSelector";
import AddressSelector from "@bloonde/location/addresses/components/AddressSelector";

const filterUIConfig = {
  uiScheme: {
    classNames: "filters-container",    
    search: {
      "ui:options": {
        label: false, // Oculta el título del campo
        placeholder: "Buscar...",
      },
    },
  },
  widgets: {
  },
};
const getFilterUIConfig: any = (type: string) => {
  return filterUIConfig;
};
export default getFilterUIConfig;
