import { useContext } from "react";
import { UnlockEventSessionsContext } from "./UnlockEventSessionsContext";
import { UnlockEventSessionsModel } from "./UnlockEventSessionsModel";

function useInitializeUnlockEventSessions() {
  const {setElement, setShowModal, setModelId} = useContext(UnlockEventSessionsContext);
  return (id: number) => {
    setModelId(id);
    setElement(new UnlockEventSessionsModel());
    setShowModal(true);
  }
}

export default useInitializeUnlockEventSessions;