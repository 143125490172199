import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/bootstrap-4";
import { useTranslation } from "react-i18next";
import { MouseEventHandler, useContext, useEffect, useState } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import BeelditFileInput from "@beeldit/core/components/BeelditFileInput";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
    context: any;
  };
}

interface Context {
  element: any;
  setElement: (element: any) => MouseEventHandler;
  backendFormErrors: any;
}

function NotificationForm(props: Prop) {
  const { formRef, onSubmit, context } = props.formConfig;
  const { element, setElement, backendFormErrors } =
    useContext<Context>(context);

  const { t } = useTranslation();

  const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});

  const translations = {
    name: t("name"),
    type: t("type"),
    structure: t("structure"),
    img: t("img"),
    url: t("url"),
    cta: t("cta"),
    subject: t("subject"),
    content: t("content"),
  };

  let schema: RJSFSchema = {
    type: "object",
    // required: ['minutes', 'customer_id', 'task', 'date', 'report_id'],
    properties: {
      name: {
        type: "string",
        title: translations.name,
      },
      // Type debe ser un selector que de momento solo tiene el valor de mail
      type: {
        type: "string",
        title: translations.type,
        default: "mail",
        oneOf: [
          {
            const: "mail",
            title: "Mail",
          },
        ],
      },
      subject: {
        type: "string",
        title: translations.subject,
      },
      structure: {
        type: "array",
        title: translations.structure,
        items: {
          type: "object",
          properties: {
            type: {
              type: "string",
              title: "Type",
              enum: ["greeting", "text", "image", "button"],
              default: "text",
            },
          },
          dependencies: {
            type: {
              oneOf: [
                {
                  properties: {
                    type: {
                      enum: ["greeting", "text"],
                    },
                    content: {
                      type: "string",
                      title: translations.content,
                      format: "textarea",
                    },
                  },
                },
                {
                  properties: {
                    type: {
                      enum: ["image"],
                    },
                    url: {
                      type: "string",
                      title: translations.url,
                    },
                  },
                },
                {
                  properties: {
                    type: {
                      enum: ["button"],
                    },
                    url: {
                      type: "string",
                      title: translations.url,
                    },
                    cta: {
                      type: "string",
                      title: translations.cta,
                    },
                  },
                },
              ],
            },
          },
        },
      },
    },
  };
  const uiSchema: UiSchema = {
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
    company_logo: {
      "ui:widget": "file-input",
    },
  };
  const customWidgets = {
    ...widgets,
    ...{ "file-input": BeelditFileInput },
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  const processSchemaFunction = useProcessSchema();

  useEffect(() => {
    setFinalSchema(processSchemaFunction(schema, element));
  }, [element]);

  return (
    <JSONSchemaForm
      noHtml5Validate
      showErrorList={false}
      ref={formRef}
      schema={schema}
      uiSchema={uiSchema}
      widgets={customWidgets}
      formData={element}
      validator={validator}
      onChange={handleChange}
      onSubmit={onSubmit}
      onError={log("errors")}
      extraErrors={backendFormErrors}
    />
  );
}

export default NotificationForm;
