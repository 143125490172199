import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

function BeelditInput(props: any) {
  const { t } = useTranslation();
  const {
    id,
    value,
    required,
    disabled,
    readonly,
    onChange,
    placeholder,
    schema,
  } = props;

  // Estado local para manejar el valor del input
  const [inputValue, setInputValue] = useState(value);

  // Crear una versión debounced de la función onChange
  const debouncedOnChange = useCallback(
    (value: any) => {
      const handler = setTimeout(() => {
        onChange(value);
      }, 500); // Espera 0.5 segundos antes de invocar onChange

      // Limpieza: Clear the timeout if the component is unmounted or the value changes
      return () => {
        clearTimeout(handler);
      };
    },
    [onChange] // Re-crear esta función si onChange cambia
  );

  // Efecto para manejar cambios en inputValue
  useEffect(() => {
    // Llama a la función debounced cada vez que inputValue cambie
    const handler = debouncedOnChange(inputValue);

    // Limpieza: asegúrate de limpiar el timeout si el componente se desmonta
    return () => {
      if (handler) handler();
    };
  }, [inputValue, debouncedOnChange]);

  // Efecto para manejar cambios externos en 'value' prop
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <>
      <input
        id={id}
        className="form-control"
        type={schema.type}
        value={inputValue}
        min={schema.minimum}
        max={schema.maximum}
        required={required}
        disabled={disabled}
        readOnly={readonly}
        placeholder={placeholder}
        onChange={(event) => setInputValue(event.target.value)}
      />
    </>
  );
}

export default BeelditInput;
