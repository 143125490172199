import { useContext } from "react";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import update from "@beeldit/core/services/update";
import { DetailsByPageableActionContext } from "../detailsByPageable/DetailsByPageableActionContext";
import { DetailsActionContext } from "../details/DetailsActionContext";

function useUpdateSection() {

  const errorHandler = useErrorHandler();

  const {
    element: page,
    setBackendFormErrors,
  } = useContext(DetailsByPageableActionContext);
  const {
    element: page2,
    setBackendFormErrors: setBackendFormErrors2,
  } = useContext(DetailsActionContext);

  const setBackendFormErrorsFinal = page ? setBackendFormErrors : setBackendFormErrors2;
  const pageFinal = page || page2;

  return (pageId: any, sectionId: any, section: any, onSuccess: any) => {
    update(`pages/${pageId}/sections`, sectionId, section)
      .then((response: any) => {
        onSuccess(response.data);
        setBackendFormErrorsFinal({});
      })
      .catch((error: any) => {
        // errors.response.data contiene una variable errors que es un objeto donde la key debería ser sections.{index}.content.elements...
        // Sin embargo en errors viene un objeto con key que  es content.elements... por lo que tenemos que cambiar la key
        // Primero tenemos que saber el indice del section dentro del page
        let sectionIndex = pageFinal.sections.findIndex(
          (s: any) => s.id == sectionId
        );
        let newErrors: any = {};
        for (let key in error.response.data.errors) {
          let newKey = key.replace("content.elements", `sections.${sectionIndex}.content.elements`);
          newErrors[newKey] = error.response.data.errors[key];
        }
        error.response.data.errors = newErrors;
        errorHandler(error, setBackendFormErrorsFinal);
      });
  };
}

export default useUpdateSection;
