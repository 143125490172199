import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/core";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import TemplateSelector from "@bloonde/cms/cms-templates/components/TemplateSelector";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { Button } from "react-bootstrap";
import BookingCard from "./BookingCard";
import BookingNotFound from "./BookingNotFound";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
    context: any;
    className?: string;
  };
}

interface Context {
  element: any;
  setElement: (element: any) => any;
  backendFormErrors: any;
}

function QRScannerForm(props: Prop) {
  const { formRef, onSubmit, context, className } = props.formConfig;
  const { element, setElement, backendFormErrors } =
    useContext<Context>(context);

  const { t } = useTranslation();

  const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});
  const translations = {
    qr: t("Código QR"),
  };

  let schema: RJSFSchema = {
    type: "object",
    // required: ['minutes', 'customer_id', 'task', 'date', 'report_id'],
    properties: {
      qr: {
        type: "string",
        title: translations.qr,
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:classNames": className,
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
  };
  const customWidgets = {
    ...widgets,
    ...{ "template-selector": TemplateSelector },
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  const onShowScanner = () => {
    setElement(null);
    setScanner(!scanner);
  };

  const preSubmit = (element: any) => {
    if (element.formData.qr) {
      onSubmit(element.formData);
    }
  };

  const scanned = (result) => {
    setScanner(false);
    setElement({ qr: result });
    setTimeout(() => {
      onSubmit({ qr: result });
    }, 500);
  };

  const processSchemaFunction = useProcessSchema();

  const [scanner, setScanner] = useState(false);

  useEffect(() => {
    setFinalSchema(processSchemaFunction(schema, element));
  }, [element]);

  return (
    <>
      <JSONSchemaForm
        noHtml5Validate
        showErrorList={false}
        ref={formRef}
        schema={schema}
        uiSchema={uiSchema}
        widgets={customWidgets}
        formData={element}
        validator={validator}
        onChange={handleChange}
        onSubmit={preSubmit}
        onError={log("errors")}
        extraErrors={backendFormErrors}
      />
      {element != null && element.id ? <BookingCard booking={element} /> : null}
      {element != null && element === "QR not found" ? (
        <BookingNotFound />
      ) : null}
      <div className="scanner-container">
        {scanner && (
          <QrScanner
            onDecode={(result) => scanned(result)}
            onError={(error) => console.log(error)}
          />
        )}
        <Button variant="primary" onClick={onShowScanner}>
          {scanner ? t("hide_qr_scanner") : t("show_qr_scanner")}
        </Button>
      </div>
    </>
  );
}

export default QRScannerForm;
