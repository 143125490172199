import { useContext } from "react";

import get from "@beeldit/core/services/get";
import { TenantAndCommerceContext } from "@main/contexts/TenantAndCommerceContext";
import { getApi } from "@beeldit/core/helpers/api";

declare var changeColor: any;

function useGet() {
  // const errorHandler = useErrorHandler();
  const { setTenant } = useContext(TenantAndCommerceContext);

  return (
    id: any,
    onSuccess: any = (data: any) => {
      return data;
    }
  ) => {
    getTenant(id)
      .then((response: any) => {
        let data = response.data;
        data = onSuccess(response.data);
        setTenant(data);

        // Guardamos en localstorage los colores por separado
        localStorage.setItem("primary_color", data.primary_color);
        localStorage.setItem("secondary_color", data.secondary_color);
        localStorage.setItem("tertiary_color", data.tertiary_color);
        // changeColor(data.primary_color, data.secondary_color, data.tertiary_color);

        
      })
      .catch((error: any) => {
        // errorHandler(error); // TODO - Lo he quitado porque hay un fallo que hace que redirija al login
      });
  };
}

async function getTenant(id: any) {
  const localstorageKey = `tenant-${id}`;
  // Comprueba si existe en localstorage y no está caducado
  if (localStorage.getItem(localstorageKey)) {
    const item = JSON.parse(localStorage.getItem(localstorageKey) as string);
    if (item.expiration > new Date().getTime()) {
      return item.value;
    }
  }
  return await getApi().get(`tenants/${id}?relations=media`);
}


export default useGet;
