import { GenerateAssignmentsContext } from "./GenerateAssignmentsContext";
import useInitialize from "./useInitialize";
import useExecute from "./useExecute";
import { createRef, useContext } from "react";
import JSONSchemaForm from "@rjsf/core";
import CrudModuleFactoryModal from "@beeldit/core/components/CrudModuleFactoryModal";
import { t } from "i18next";
import EventSessionGenerateForm from "@main/events/components/EventSessionGenerateForm";
import GenerateAssignmentsForm from "../../components/GenerateAssignmentsForm";

function useGenerateAssignmentsAction(index: any): any {
  const initialize = useInitialize();
  const initializeFunction = (id: number) => initialize(id);

  const onSuccessExecute = () => {
    setShowModal(false);
  };

  const execute = useExecute();

  const executeFunction = () => execute(onSuccessExecute);

  const { setShowModal } = useContext(GenerateAssignmentsContext);

  const formRef = createRef<JSONSchemaForm>();

  const { element } = useContext(GenerateAssignmentsContext);

  const modalConfig = {
    context: GenerateAssignmentsContext,
    title: t("generate_assignments"),
    onSubmit: () => executeFunction(),
  };

  /**
   * Form configuration definition
   */
  const formConfig = {
    formRef: formRef, // We pass the ref to the form
    onSubmit: () => {
      executeFunction();
    },
    element: element, // We pass the element to the form in order to render it in the modal form
    context: GenerateAssignmentsContext,
  };

  const actionTemplate = (
    <CrudModuleFactoryModal modalConfig={modalConfig}>
      <GenerateAssignmentsForm formConfig={formConfig} />
      {t("are_you_sure_you_want_to_generete_the_assignments")}
    </CrudModuleFactoryModal>
  );

  /** END EDIT TABLE ACTION */

  return {
    initializeFunction,
    actionTemplate,
  };
}

export default useGenerateAssignmentsAction;
