const EventSessionPricesColsSchema = [
  [
    {
      name: "event_price_type_name",
      cols: "col-6",
    },
    {
      name: "event_rule_price",
      cols: "col-6",
    },
  ],
];

export default EventSessionPricesColsSchema;
