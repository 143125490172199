import useGet from "./useGet";

function useGetTenantAction(): any {
  const get = useGet();
  const getTenantFunction = (id: number) => get(id);

  return {
    getTenantFunction
  };
}

export default useGetTenantAction;
