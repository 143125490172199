import { ObjectFieldTemplateProps, RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/core";
import { useEffect, useRef, useState } from "react";

interface Prop {
  formConfig: {
    element: any;
    schema: any;
    uiSchema?: RJSFSchema | undefined;
    widgets?: any;
    onChange: any;
  };
}

function ObjectFieldTemplate(props: ObjectFieldTemplateProps) {
  return (
    <div className="form-row d-flex flex-row justify-content-end">
      {props.title}
      {props.description}
      {props.properties.map((element) => (
        <div className="property-wrapper">{element.content}</div>
      ))}
    </div>
  );
}

function BeelditTableFilters(props: Prop) {
  const { element, schema, onChange, uiSchema, widgets } = props.formConfig;

  const [lastTableFilters, setLastTableFilters] = useState({} as any);
  const [tableFilters, setTableFilters] = useState({} as any);
  const lastTableFiltersRef = useRef();

  const defaultScheme: UiSchema = {
    // "ui:globalOptions" : {
    //   "label": false,
    // },
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
  };
  const log = (type: any) => console.log.bind(console, type);

  const mergedUiScheme = { ...uiSchema, ...defaultScheme };

  useEffect(() => {
    setLastTableFilters(element);
    setTableFilters(element);
  }, []);

  const onChangeFilter = (form: any) => {
    lastTableFiltersRef.current = form.formData; // Actualiza la referencia
    setLastTableFilters(form.formData);
    setTableFilters(form.formData);
    setTimeout(() => {
      if (
        JSON.stringify(form.formData) !==
        JSON.stringify(lastTableFiltersRef.current)
      ) {
        // console.log("No se hace filtro porque ha cambiado");
        return;
      }
      // console.log("Se hace filtro porque no ha cambiado");
      onChange(form);
    }, 500);
  };
  return (
    <>
      <JSONSchemaForm
        schema={schema}
        uiSchema={mergedUiScheme}
        formData={tableFilters}
        validator={validator}
        onChange={onChangeFilter}
        widgets={widgets ? widgets : {}}
        onError={log("errors")}
        templates={{ ObjectFieldTemplate }}
      />
    </>
  );
}

export default BeelditTableFilters;
