import CommerceSelector from "@main/commerces/components/CommerceSelector";
import EventTypeSelector from "@main/event-types/components/EventTypeSelector";
import RoomSelector from "@main/rooms/components/RoomSelector";

const filterUIConfig = {
  uiScheme: {
    classNames: "filters-container",
    // customer_id: {
    //     "ui:widget": "customer-selector",
    //     "ui:options": {
    //         label: false, // Oculta el título del campo
    //     }
    // },
    // report_id: {
    //     "ui:widget": "report-selector",
    //     "ui:options": {
    //         label: false // Oculta el título del campo
    //     }
    // }
    event_type_id: {
      "ui:widget": "event-type-selector",
      "ui:options": {
        label: false, // Oculta el título del campo
      },
    },
    room_id: {
      "ui:widget": "room-selector",
      "ui:options": {
        label: false, // Oculta el título del campo
      },
    },
    search: {
      "ui:options": {
        label: false, // Oculta el título del campo
        placeholder: "Buscar...",
      },
    },
  },
  widgets: {
    "commerce-selector": CommerceSelector,
    "event-type-selector": EventTypeSelector,
    "room-selector": RoomSelector,
    // 'report-selector': ReportSelector,
    // 'customer-selector': CustomerSelector
  },
};
const getFilterUIConfig: any = (type: string) => {
  return filterUIConfig;
};
export default getFilterUIConfig;
