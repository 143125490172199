import React, { createContext, useState } from "react";

interface SalesGraphContextType {  
  graphData: any[];
  setGraphData: (graphData: any[]) => void;  
}

export const SalesGraphContext = createContext<SalesGraphContextType>({  
  graphData: [],
  setGraphData: () => {},  
});

interface SalesGraphProviderProps {
  children: React.ReactNode;
}

export const SalesGraphProvider: React.FC<SalesGraphProviderProps> = ({
  children,
}) => {
  const [graphData, setGraphData] = useState<any[]>();

  const value = {
    graphData,
    setGraphData,
  };
  return (
    <SalesGraphContext.Provider value={value}>
      {children}
    </SalesGraphContext.Provider>
  );
};
