import { ObjectFieldTemplateProps } from "@rjsf/utils";

function GalleryTemplate(props: ObjectFieldTemplateProps, func: any) {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="property-wrapper">
            {func("img", props)?.content}
          </div>
        </div>
        <div className="col-12">
          <div className="property-wrapper">
            {func("alt", props)?.content}
          </div>
        </div>
        {
          func("main", props)?.content && (
            <div className="col-12">
              <div className="property-wrapper">
                {func("main", props)?.content}
              </div>
            </div>
          )
        }
      </div>
    </>
  );
}

export default GalleryTemplate;
