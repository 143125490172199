import React, { createContext, useState } from "react";

interface ConfigurationsContextType {
  langs: string[];
  setLangs: (langs: string[]) => void;
  schemas: {};
  setSchemas: (schemas: any) => void;
}

export const ConfigurationsContext = createContext<ConfigurationsContextType>({
  langs: [],
  setLangs: () => {},
  schemas: {},
  setSchemas: () => {},
});

interface ConfigurationsProviderProps {
  children: React.ReactNode;
}

export const ConfigurationsProvider: React.FC<ConfigurationsProviderProps> = ({
  children,
}) => {
  const [langs, setLangs] = useState<string[]>([]);
  const [schemas, setSchemas] = useState({});

  const setInLocalstorage = (key: string, value: any) => {
    // Guardamos en localstorage con una fecha de expiración de 1 semana
    localStorage.setItem(
      key,
      JSON.stringify({
        value: {
          data: value,          
        },
        expiration: new Date().getTime() + 1000 * 60 * 60 * 24 * 7,
      })
    );
  };

  const setSchemaInLocalstorage = (value: any) => {
    setSchemas(value);
    setInLocalstorage("schemas", value);
  };
  const setLangsInLocalstorage = (value: any) => {
    setLangs(value);
    setInLocalstorage("langs", value);
  }

  const value = {
    langs,
    setLangs: setLangsInLocalstorage,
    schemas,
    setSchemas: setSchemaInLocalstorage,
  };
  return (
    <ConfigurationsContext.Provider value={value}>
      {children}
    </ConfigurationsContext.Provider>
  );
};
