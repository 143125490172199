import { RJSFSchema, UiSchema } from '@rjsf/utils';
import { customizeValidator } from '@rjsf/validator-ajv8';
import JSONSchemaForm from '@rjsf/core';
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from 'react';
import { Context } from 'vm';
import BeelditTypeSelector from '@beeldit/core/components/BeelditTypeSelector';
import useProcessSchema from '@beeldit/core/hooks/useProcessSchema';
import customFormats from '@beeldit/core/custom-validations/custom-validation';

interface Prop {
    formConfig: {
        formRef: any,
        onSubmit: any,
        context: any,
    },
}

function CouponForm(props: Prop) {

    const {formRef, onSubmit, context} = props.formConfig;    
    const {element, setElement, backendFormErrors} = useContext<Context>(context);

    const { t } = useTranslation();

    const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});

    const translations = {
        code: t('code'),
        status_id: t('status_id'),
        type_id: t('type_id'),
        start_date: t('start_date'),
        end_date: t('end_date'),
        initial_amount: t('initial_amount'),
        amount: t('amount'),
        uses: t('uses'),
        remaining_uses: t('remaining_uses'),
        uses_same_user: t('uses_same_user'),
        min_purchase: t('min_purchase'),
    }
  
    let schema: any = {
        type: 'object',
        required: ['code', 'status_id', 'type_id'],
        properties: {
            code: { type: 'string', title: translations.code},
            id: { 
                type: ['integer', 'null'] 
            },
            status_id: {
                type: 'integer', 
                title: translations.status_id,
                default: 0,
            },
            type_id: { 
                type: 'integer', 
                title: translations.type_id,
                default: 0,
            },
            start_date: { 
                type: ['string', 'null'], 
                title: translations.start_date,
                format: "date-time",            
            },
            end_date: { 
                type: ['string', 'null'], 
                title: translations.end_date,
                format: "date-time",            
            },
            initial_amount: { 
                type: 'number', 
                title: translations.initial_amount,
                default: 0,
                minimum: 0,            
            },
            amount: { 
                type: 'number', 
                title: translations.amount,
                default: 0,
                minimum: 0,     
                show: element && element.id && element.type_id == 3? true : false
            },
            uses: { 
                type: 'integer', 
                title: translations.uses,
                default: 1,
                minimum: 0,
                show: element && element.type_id !== 2        
            },
            remaining_uses: {
                type: 'integer',
                title: translations.remaining_uses,
                default: 0,
                minimum: 0,
                show: element && element.id && element.type_id !== 2
            },
            // uses_same_user: {
            //     type: 'integer',
            //     title: translations.uses_same_user,
            //     default: 1,
            //     minimum: 0,
            //     show: element && element.type_id !== 2
            // },
            // min_purchase: {
            //     type: 'integer',
            //     title: translations.min_purchase,
            //     default: 0,
            //     minimum: 0,
            // }
        },   
    };
    const uiSchema: UiSchema = {
      "ui:submitButtonOptions": {
        "props": {
          "disabled": false,
          "className": "btn btn-info"
        },
        "norender": true,
      },
      id: {
        'ui:widget': 'hidden'
      },
      status_id: {
        "ui:widget": "type-selector",
        "ui:options": {
            "configPath": "types.coupons.status_id"
        }
      },
      type_id: {
        "ui:disabled": element.id ? true : false,
        "ui:widget": "type-selector",
        "ui:options": {
            "configPath": "types.coupons.type_id"
        }
      },
    //   initial_amount: {
    //     "ui:disabled": element.id ? true : false,
    //   },
      uses: {
        "ui:disabled": element.id ? true : false,
      },
    };
    const widgets = {
        'type-selector': BeelditTypeSelector,
    };
    const handleChange = (type: any) => { /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
      // Actualizar el estado solo si no hay errores de validación
      setElement(type.formData);
      return console.log.bind(console, type)
    };
    const log = (type: any) => {
      return console.log.bind(console, type)
    };

    
    const formats = customFormats;  
    const validator = customizeValidator({ customFormats: formats });

    const processSchemaFunction = useProcessSchema();

    useEffect(() => {
        setFinalSchema(processSchemaFunction(schema, element));
    }, [element])

    
    return (
        <>
        {finalSchema ? (
            <>
            <JSONSchemaForm
            noHtml5Validate
            showErrorList={false}
            ref={formRef}
            schema={finalSchema}
            uiSchema={uiSchema}
            widgets={widgets}
            formData={element}
            validator={validator}
            onChange={handleChange}
            onSubmit={onSubmit}
            onError={log('errors')}
            extraErrors={backendFormErrors}
          />            
            </>
        ) : null}
        </>
    )
}

export default CouponForm;
