import { RJSFSchema, UiSchema } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/core";
import { useTranslation } from "react-i18next";
import { UserContext } from "../contexts/UserContext";
import { useContext, useEffect, useState } from "react";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import RoleSelector from "./RoleSelector";
import { Context } from "vm";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
    context: any;
  };
}

function UserForm(props: Prop) {
  const { formRef, onSubmit, context } = props.formConfig;

  const { element, setElement, backendFormErrors } = useContext<Context>(context);

  const { t } = useTranslation();

  const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});

  const translations = {
    name: t("name"),
    surnames: t("surnames"),
    email: t("email"),
    password: t("password"),
    password_confirmation: t("password_confirmation"),
    role: t("role"),
  };

  let schema = {
    type: "object",
    properties: {
      name: { type: "string", title: translations.name },
      surnames: { type: "string", title: translations.surnames },
      email: { type: "string", title: translations.email },
      password: {
        type: "string",
        title: translations.password,
        show: element && element.id ? false : true,
      },
      password_confirmation: {
        type: "string",
        title: translations.password_confirmation,
        show: element && element.id ? false : true,
      },
      role: {
        type: "string",
        title: translations.role,
        default: "admin",
      },
    },
  };
  const uiSchema: UiSchema = {
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
    password: {
      "ui:widget": "password",
    },
    password_confirmation: {
      "ui:widget": "password",
    },
    role: {
      "ui:widget": "role-selector",
    },
  };

  const customWidgets = {
    ...widgets,
    ...{
      "role-selector": RoleSelector,
    },
  };

  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const processSchemaFunction = useProcessSchema();

  useEffect(() => {
    setFinalSchema(processSchemaFunction(schema, element));
  }, [element]);

  return (
    <>
      <JSONSchemaForm
        noHtml5Validate
        showErrorList={false}
        ref={formRef}
        schema={finalSchema}
        uiSchema={uiSchema}
        widgets={customWidgets}
        formData={element}
        validator={validator}
        onChange={handleChange}
        onSubmit={onSubmit}
        onError={log("errors")}
        extraErrors={backendFormErrors}
      />
    </>
  );
}

export default UserForm;
