import useGetConfiguration from "./useGetConfiguration";

function useGetConfigurationAction(): any {
  const get = useGetConfiguration();
  const getConfigurationFunction = (configurationKey: string, onSuccess: () => {}) => get(configurationKey, onSuccess);

  return {
    getConfigurationFunction
  };
}

export default useGetConfigurationAction;
