import { useContext } from "react";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import store from "@beeldit/core/services/store";

import { DetailsActionContext } from "./DetailsActionContext";

function useCheck() {
  const { setElement, setBackendFormErrors } = useContext(DetailsActionContext);
  const errorHandler = useErrorHandler();

  return (element, onSuccess: any) => {
    store("bookings/check-qr", element)
      .then((response: any) => {
        setElement(null);
        onSuccess(response.data);
      })
      .catch((error: any) => {
        setElement(null);
        console.error("Error fetching data:", error);
        if (error.response) {
          errorHandler(error, setBackendFormErrors);
        }
      });
  };
}

export default useCheck;
