import widgets from "@beeldit/core/widgets/widgets";

const filterUIConfig = {
    uiScheme: {
        classNames: "filters-container",
        // customer_id: {
        //     "ui:widget": "customer-selector",
        //     "ui:options": {
        //         label: false, // Oculta el título del campo
        //     }
        // },
        // report_id: {
        //     "ui:widget": "report-selector",
        //     "ui:options": {
        //         label: false // Oculta el título del campo
        //     }
        // },
        search: {
            "ui:options": {
                label: false, // Oculta el título del campo
                placeholder: "Buscar..."
            }
        },
        start_date: {
            "ui:options": {
                label: true, // Oculta el título del campo
                placeholder: "Desde",
                title: "Desde"
            }
        },
        end_date: {
            "ui:options": {
                label: true, // Oculta el título del campo
                placeholder: "Hasta",
                title: "Hasta"
            }
        },
        
    },
    widgets: widgets
}
const getFilterUIConfig: any = (type: string) => {
    return filterUIConfig;
}
export default getFilterUIConfig;