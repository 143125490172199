import { XCircle } from "@phosphor-icons/react/dist/ssr";
import { ArrayFieldTemplateProps } from "@rjsf/utils";
import { t } from "i18next";
import useUpdateSectionAction from "../../actions/updateSection/useUpdateSectionAction";

function GalleryArrayTemplate(
  props: ArrayFieldTemplateProps,
  className: string,
  id: string
) {
  const {actionTemplate} = useUpdateSectionAction();
  return (
    <div className={className}>
      {id != "root_sections" ? <h3>{props.title} {actionTemplate}</h3> : null}
      <div className="my-2">
        <div className="row">
          {props.items.map((element, index) => {
            return (
              <>
                <div className="col-4">
                  {id != "root_sections" ? (
                    <div className="mb-3 d-flex align-items-center">
                      <div className="h5 element-title">
                        {t('image')} {index + 1}{" "}
                      </div>
                      {props.canAdd && (
                        <XCircle
                          size={24}
                          className="ms-2 pe-hand text-danger"
                          onClick={element.onDropIndexClick(index)}
                        />
                      )}
                    </div>
                  ) : null}
                  <div className="flex-grow-1">{element.children}</div>
                </div>
              </>
            );
          })}
          <div className="col-12 text-right">
            {props.canAdd && (
              <button
                className="btn btn-outline-danger ml-auto"
                onClick={props.onAddClick}
              >
                + {t('add_image')}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GalleryArrayTemplate;
