import { useContext } from "react";
import { TestEmailContext } from "./TestEmailContext";

function useInitializeGenerateEventSessions() {
  const { setShowModal, setModelId } =
    useContext(TestEmailContext);
  return (id: number) => {
    setModelId(id);    
    setShowModal(true);
  };
}

export default useInitializeGenerateEventSessions;
