import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/core";
import { useTranslation } from "react-i18next";
import { EventRulePriceContext } from "../contexts/EventRulePriceContext";
import { useContext, useEffect, useState } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import EventSelector from "@main/events/components/EventSelector";
import EventPriceTypeSelector from "@main/event-price-types/components/EventPriceTypeSelector";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
  };
}

function EventRulePriceForm(props: Prop) {
  const { formRef, onSubmit } = props.formConfig;
  const { element, setElement, backendFormErrors } = useContext(
    EventRulePriceContext
  );

  const { t } = useTranslation();

  const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});

  const translations = {
    event_id: t("Event"),
    event_price_type_id: t("Event Price Type"),
    amount: t("Amount"),
    capacity: t("Capacity"),
  };

  let schema: RJSFSchema = {
    type: "object",
    required: ["event_id", "event_price_type_id", "amount"], // required: ['minutes', 'customer_id', 'task', 'date', 'report_id'],
    properties: {
      event_id: {
        type: "integer",
        title: translations.event_id,
      },
      event_price_type_id: {
        type: "integer",
        title: translations.event_price_type_id,
      },
      amount: {
        type: "number",
        title: translations.amount,
      },
      capacity: {
        type: "integer",
        title: translations.capacity,
      },
    },
  };
  const uiSchema: UiSchema = {
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
    event_id: {
      "ui:widget": "event-selector",
    },
    event_price_type_id: {
      "ui:widget": "event_price_type-selector",
    },
  };
  const customWidgets = {
    ...widgets,
    ...{ "event-selector": EventSelector },
    ...{ "event_price_type-selector": EventPriceTypeSelector },
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  const processSchemaFunction = useProcessSchema();

  useEffect(() => {
    setFinalSchema(processSchemaFunction(schema, element));
  }, [element]);

  return (
    <JSONSchemaForm
      noHtml5Validate
      showErrorList={false}
      ref={formRef}
      schema={schema}
      uiSchema={uiSchema}
      widgets={customWidgets}
      formData={element}
      validator={validator}
      onChange={handleChange}
      onSubmit={onSubmit}
      onError={log("errors")}
      extraErrors={backendFormErrors}
    />
  );
}

export default EventRulePriceForm;
