import React from "react";

import { RemoveActionProvider } from "@bloonde/cms/cms-pages/actions/remove/RemoveActionContext";
import { CreateActionProvider } from "@bloonde/cms/cms-pages/actions/create/CreateActionContext";
import { ListActionProvider } from "@bloonde/cms/cms-pages/actions/list/ListActionContext";
import PagesPage from "@bloonde/cms/cms-pages/pages/PagesPage";


export default function PageRouterWrapper(props: { config:any,  filters: any, initialUrlFragment: string, key: string,
    formDefaultValues?: any, formFilterValues?: any }) {
  const { config, filters, key, formDefaultValues, formFilterValues } = props;

  return (
      <ListActionProvider key={key}>
          <RemoveActionProvider>
              <CreateActionProvider>
                  <PagesPage
                      config={config}
                      filters={filters}
                      formDefaultValues={formDefaultValues}
                      formFilterValues={formFilterValues}
                  />
              </CreateActionProvider>
          </RemoveActionProvider>
      </ListActionProvider>
  );
};
